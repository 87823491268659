// import React from 'react';
import React, { useEffect, useState } from "react";
import axios from 'axios';
import {
    Link
  } from 'react-router-dom';
  import { Grid } from 'antd-mobile';
  import AppHeader from './AppHeader';



const styles = {
    // bgColor: 'white',
    titleTextColor: "#00008B",
    rowTitleColor: "#00008B",
    // rowContentColor: 'grey',
    // arrowColor: "red",
};

const config = {
    animate: true,
    arrowIcon: "V",
    tabFocus: true
};

let NUM_ROWS = 10;

let URL = "http://AThaPyar.com/";

class AndroidDownloadPage extends React.Component {
    constructor(props) {
            super(props)
            window.BPdata.footerMenu = "HelpCenter";

            this.state = {
                banners: [],
                select_banners: [],
                step: "login",
                message : "",
                title: "",
                promotion_id : 0,

            };

        }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } else {
            if(menu[menu.length - 1] !== url ){
              menu.push(url)
              localStorage.setItem("route", JSON.stringify(menu) );
            }
          }

          // localStorage.setItem("route", JSON.stringify(menu) );
          // console.log(localStorage.getItem('route'));
        }
       // console.log(localStorage.getItem('route'));
    }


    componentDidMount() {

        if(localStorage.getItem('banners')) {

          if(JSON.parse(localStorage.getItem("banners")) != null) {
            let video_slides = JSON.parse(localStorage.getItem("banners"));
            this.setState({banners: video_slides})
          }
        }


        window.scrollTo(0, 0);


    }



    render() {
        return (
            <div className="page">
                {/* <AppHeader backLink="" titleType="2" title={window.lang.more.share_app} isLoading="false" /> */}
                <div className="page-content">

                    <div className="main-content ">
                        <div className="row">

                            <div className="grid-100 text-center">
                                

                            <br />
                            <br />
                            <h2 className="color-theme-gold" style={{ "fontSize": "24px"}}>AThaPyar App ကို Android သမားများအတွက် ဖုန်းထဲကို အက်ပလီကေးရှင်းထည့်နည်း</h2>
                            <span><br /><br />

                            ✅ပထမဦးဆုံး Browser ထဲကိုဝင်ပြီး <a href="https://AThaPyar.com" target="_blank" className="color-blue">https://AThaPyar.com</a> ကိုရိုက်ရှာကာ ဝက်ဆိုဒ်ထဲသို့ဝင်ပါ။<br />
                            ၁။ Browser ရဲ့ setting အစက်လေးသုံးစက် ကိုနှိပ်ပါ <br /> (Browser အမျိုးအစားပေါ်မူတည်ပြီး ကွဲပြားနိုင်ပါသည်။) <br />
                            ၂။ Add to Home Screen (သို့) Install App ကိုနှိပ်ပါ <br />
                            ၃။ လူကြီးမင်းရဲ့ဖုန်းထဲသို့ AThaPyar icon လေးရောက်ရှိနေတာ တွေ့ပါလိမ့်မယ် <br /></span>
                            <br />
                            <br />
                            <img src="/img/guide/android1.JPG" className="img-responsive" /><br />
                            <br />
                            {/* <img src="/img/guide/android2.jpg" className="img-responsive" /><br /> */}
                            <br />
                            {/* <img src="/img/guide/android3.jpg" className="img-responsive" /><br /> */}
                            <br />
                            {/* <img src="/img/guide/android4.jpg" className="img-responsive" /><br /> */}
                            <br />
                            <a href="https://AThaPyar.com" target="_blank" className="color-blue">https://AThaPyar.com</a> 

                            </div>
                        </div>
                      <br />

                    <br />
                    </div>
                </div>

                <footer  className="App-footer">

                    <div className="App-footer-inner">
                      {/* {window.BPdata.footerMenu} */}
                      <Grid data={window.lang.footer}
                          columnNum={5}
                          renderItem={dataItem => (

                            <Link to={dataItem.url} className="cont-wrap-link hvr-pulse-shrink" style={{width: '100%'}}>
                                  { (window.BPdata.footerMenu === dataItem.check )  ? (dataItem.text == "My Profile") ?  <span className="color-white"><img src={dataItem.icon}  height="25px"/><br />{(this.checklogin()) ? dataItem.text : "Login / Register" }</span> : <span className="text-white"><img src={dataItem.icon_off}  height="25px"/><br /><span >{dataItem.text}</span> </span> : <span className="text-white"><img src={dataItem.icon}  height="25px"/><br />{dataItem.text} </span> }

                            </Link>
                          )}
                        />

                    </div>
                  </footer>
            </div>
        );
    }
}

export default AndroidDownloadPage;

