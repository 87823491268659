import React, { useState, useEffect } from "react";
// import queryString from 'query-string';
import io from "socket.io-client";

import TextContainer from '../TextContainer/TextContainer';
import Messages from '../Messages/Messages';
import InfoBar from '../InfoBar/InfoBar';
import Input from '../Input/Input';

import './Chat.css';

let socket;

const Chat = () => {
  let [name, setName] = useState('');
  const [room, setRoom] = useState('');
  const [users, setUsers] = useState('');
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  // const ENDPOINT = 'http://project-chat-application.herokuapp.com/';
//   const ENDPOINT = 'http://127.0.0.1:5000/';
//  const ENDPOINT = 'https://stream.BPdata.com:8181/';
  const ENDPOINT = 'http://139.162.19.63:5000/';
  

  useEffect(() => {
    // var d = new Date();
    // var n = d.getUTCSeconds();
    var n = Math.floor(Math.random() * 10000) + 1;

    let username = localStorage.getItem('username');

    if(username) {
      name = username +n
      setName(name);
    } else {
      name = "User"+n;
      setName(name);
    }
    
    let room = "Channel 1";
    //const { name, room } = queryString.parse(location.search);

    socket = io(ENDPOINT);

    setRoom(room);
    

    socket.emit('join', { name, room }, (error) => {
      if(error) {
        alert(error);
      }
    });
  }, [ENDPOINT, ""]);
  
  useEffect(() => {
    socket.on('message', message => {
      setMessages(messages => [ ...messages, message ]);
    });
    
    socket.on("roomData", ({ users }) => {
      setUsers(users);
    });
}, []);

  const sendMessage = (event) => {
    event.preventDefault();

    if(message) {
      socket.emit('sendMessage', message, () => setMessage(''));
    }
  }

  return (
            <div className="outerContainer chlivechat">
              <div className="container-chat1">
                  <InfoBar room={room}  total={users.length}/>
                  {/* <Messages messages={messages} name={name} />
                  <Input message={message} setMessage={setMessage} sendMessage={sendMessage} total="1"/> */}
              </div>
              {/* <TextContainer users={users}/> */}
            </div>
  )                                                                                                                                                                                                                                                                                                         
}

export default Chat;
