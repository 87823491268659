import React from 'react';
import axios from 'axios';
import { Modal }  from 'antd-mobile';
import {
    Link
  } from 'react-router-dom';


const alert = Modal.alert;
// const text = <span>prompt text</span>;
// var moment = require('moment');

class TransactionPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = { step: "login", message : "", form : {username: '', phone: '',verify_code: '', password: '' },id:"",username:"",phone:"",created_at:"",gem:"",league_points:"0",max_league_points:"0",rank:""};
        
        this.userLogout         = this.userLogout.bind(this);
        this.langSwitch         = this.langSwitch.bind(this);
    }

    lpad(value, padding) {
        var zeroes = new Array(padding+1).join("0");
        return (zeroes + value).slice(-padding);
    }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } 
           if(menu.length > 0) {
              //console.log(menu[menu.length - 1] + "-" + url )
                if(menu[menu.length - 2] === url ){
                    //console.log("first");
                    menu.pop();
                    //window.BPdata.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                    //localStorage.setItem("route", JSON.stringify(menu) );
                }  
                else if(menu[menu.length-1] !== url ){
                    menu.push(url)
                    //window.BPdata.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                }
                window.BPdata.preurl =  menu[menu.length-2];
            }
        }
      }

    fetchUserData() {
        
        const url = window.BPdata.apiUrl+'/user/token/'+localStorage.getItem(window.BPdata.time);
    
        axios.get(url, {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "no-cors", // no-cors, cors, *same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
          headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
          },
          redirect: "follow", // manual, *follow, error
          referrer: "no-referrer", // no-referrer, *client
          //body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
        .then(resp => {
          // console.log(this.data.concat(resp.data));
          //let latest_data = resp.data;
          //  console.log('-----------------------')
            //console.log(latest_data)
            //console.log(resp.data);
            this.setState({
              id : resp.data.id,
              username : resp.data.username,
              phone : resp.data.phone,
              created_at : resp.data.created_at,
              gem       : resp.data.gem,
              league_points : resp.data.league_points,
              max_league_points : resp.data.max_league_points,
              rank : resp.data.rank
            });
            
          })
          .catch(function(error) {
            // console.log(error);
          });   
    }

    componentDidMount() {
        // console.log(mmph.normalizeInput('+၉၅၉၇၈၄၁၂၃၄၅၆')); // returns 09784123456
        // console.log(mmph.normalizeInput('+959784123456')); // returns 09784123456
        // console.log(mmph.getTelecomName('09978412345')); // returns Ooredoo
        // console.log(mmph.getPhoneNetworkType('09978412345')); // returns GSM
        // console.log(mmph.isValidMMPhoneNumber('09978412345')); // returns true if it meets Myanmar Phone
        if(localStorage.getItem(window.BPdata.time)) {
            if(localStorage.getItem(window.BPdata.time) !== "0" ) {
              //localStorage.setItem(window.BPdata.time, true);
                
              } else {
                this.props.history.push("/more");
                }
          } else {
              this.props.history.push("/more");
          }

        this.fetchUserData();

       
        this.hisUrl('/more/transaction');
        //localStorage.setItem('route', '/more/login');
        //console.log(localStorage.getItem('route'))
    }

    // componentWillUpdate() {
    //     localStorage.setItem('route', '/more/login');
    //     console.log(localStorage.getItem('route'))
    // }

    inputChangeHandler(e) {
        let form = {...this.state.form};
        form[e.target.name] = e.target.value;
        this.setState({
            form
        });
    }

    langSwitch() {

        if(localStorage.getItem('lang') === 'en') {
            localStorage.setItem('lang', 'mm');
            window.lang = window.mmlang;
        } else {
            localStorage.setItem('lang', 'en');
            window.lang = window.enlang;
        }
        
        // console.log(window.lang)
        this.props.history.push("/more/myaccount");

        //console.log(this.state.step);
     
        // alert('Message', 'အကောင့်မှ ထွက်ရန် သေချာပါသလား။', [
        //     { text: 'မထွက်ပါ', onPress: () => console.log('cancel') },
        //     {
        //     text: 'ထွက်မည်',
        //     onPress: () => {
                    
        //         }
                
        //     },
        // ])
    }

    userLogout() {
        //console.log(this.state.step);
     
        alert('Message', 'အကောင့်မှ ထွက်ရန် သေချာပါသလား။', [
            { text: 'မထွက်ပါ', onPress: () => console.log('cancel') },
            {
            text: 'ထွက်မည်',
            onPress: () => {
                    localStorage.setItem(window.BPdata.time, 0);
                    localStorage.setItem('username', "");
                    this.setState({ step: "login" , message : "Successfully Log Out" }); 
                    //this.profileBlock()
                    //this.viewHandler();
//                    this.props.history.push("/more");
                    this.props.history.push("/");
                }
                
            },
        ])

        // localStorage.setItem(window.BPdata.time, 0);
        // localStorage.setItem('username', "");
        // this.setState({ step: "login" , message : "Successfully Log Out" }); 
        // //this.profileBlock()
        // this.viewHandler();
    }

  
   

    viewHandler() {
        if(this.state.step){
            switch(this.state.step) {
                case "home":    return this.homeBlock();
                case "profile": return this.profileBlock();
                default:        return "";
              }
        }
       
    }


    profileBlock() {
      return <div>
          <div className="row">
              <h2>Hi {((this.state.form.username)? this.state.form.username : "Customer")}</h2>
              <p>Welcome to BPdata</p>
              <input type="submit" className="btn btn-blue grid-50" value="Log Out" onClick={this.userLogout} />
              {/* <a className="btn btn-blue grid-50" onClick={this.userLogout}>Logout</a> */}
          </div>
      </div>
   }

    homeBlock() {
        return <div>
            <div className="row">
                <a href="/more/login" className="btn btn-success grid-50">Log in</a><br /><br />
                <a href="/more/sign-up" className="btn btn-blue grid-50">Sign up</a>
            </div>
        </div>
    }

    render() {
        return (
            <div className="page">
                <div className="page-content text-left">
                         
                        <br/>
                        <div className="list-header">
                            <img src="/img/badge.jpg" className="list-img-circle" alt={window.lang.title.payment} /><span>{window.lang.title.payment}</span>
                        </div>
                        
                        <br />
                        <div className="row text-left">
                            <ul className="secondsubmenu">
                                <li>
                                    <Link to="/more/inbox" className="btn-success btn" >
                                        {window.lang.title.inbox}
                                    </Link> 
                                </li>
                                <li>
                                    <Link to="/more/transaction" className="btn-success btn color-gold" >
                                        {window.lang.title.payment}
                                    </Link> 
                                </li>
                                <li>
                                    <Link to="/more/order" className="btn-success btn" >
                                        {window.lang.title.order}
                                    </Link> 
                                </li>
                            </ul>
                        </div>
                        <br/>
                        {/* <table className="table">
                            <tbody>
                                <tr>
                                    <td>{window.lang.form.user}</td>
                                    <td>{this.state.username}</td>
                                </tr>
                                <tr>
                                    <td>{window.lang.form.id}</td>
                                    <td>{this.state.id}</td>
                                </tr>
                                <tr>
                              
                                <tr>
                                    <td>{window.lang.form.point}</td>
                                    <td><span className="color-green">{this.state.league_points}</span></td>
                                </tr>
                                <tr>
                                    <td>{window.lang.form.hightest_point}</td>
                                    <td><span className="color-green">{this.state.max_league_points}</span></td>
                                </tr>
                                <tr>
                                    <td>{window.lang.form.rank}</td>
                                    <td><span className="color-green">{this.state.rank}</span></td>
                                </tr>
                                <tr>
                                    <td>{window.lang.form.join_date}</td>
                                    <td>{moment(this.state.created_at, 'YYYY-MM-DD hh:mm:ss').format('DD MMM YYYY')}</td>
                                </tr>
                                <tr>
                                    <td>{ window.lang.form.language } ({(localStorage.getItem('lang') === 'mm') ? 'Myanmar': 'English'})</td>
                                    <td>
                                        <label className="switch">
                                            <input type="checkbox"   onChange={this.langSwitch}/>
                                            <span className="sslider round"></span>
                                        </label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>*/}
                        <div className="row text-center">
                            <br /> 
                            <b>Comming Soon</b>
                        </div>
                        <br /> 


                    <div className="ant-back-footer">
                        <div className="ant-back-top">
                            <div className="ant-back-top-content">
                                <Link to={window.BPdata.preurl} className="color-green" >
                            { (JSON.parse(localStorage.getItem("route")) !== null) 
                            ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                            </Link> 
                            </div>
                        </div>
                    </div>
                   
                </div>
               
            </div>
        );
    }
}

export default TransactionPage;