/* eslint no-dupe-keys: 0 */
import React from 'react';
import { ListView } from 'antd-mobile';

// import { FacebookProvider, CommentsCount } from 'react-facebook';

import {
  Link
} from 'react-router-dom';

import axios from 'axios';
var moment = require('moment');

let NUM_ROWS = 20;
let LIMIT_ROWS = 20;
let TOTAL_INDEX = 0;
let MAX_LENGTH = 90;

function genData(pIndex = 0) {
  const dataBlob = {};
  for (let i = 0; i < NUM_ROWS; i++) {
    const ii = (pIndex * NUM_ROWS) + i;
    dataBlob[`${ii}`] = `row - ${ii}`;
  }
  return dataBlob;
}

class ListPage extends React.Component {
  constructor(props) {
    super(props);

    

    var dataSource = new ListView.DataSource({
      rowHasChanged: (r1, r2) => r1 !== r2
    });

    this.data = [];
    this.state = {
      
      dataSource: dataSource.cloneWithRows(this.data),
      isLoading: true,
      
    };

    this.pageIndex = 1;
    TOTAL_INDEX = 0;
    NUM_ROWS = 20;
    if(this.props.perPage) {
      NUM_ROWS = this.props.perPage;
      TOTAL_INDEX = this.props.pageIndexTotal;

      console.log(TOTAL_INDEX)

      this.state.isLoading = true;
      
    }
  }

  componentDidMount() {
    //TOTAL_INDEX = 20;
    // you can scroll to the specified position
    setTimeout(() => this.lv.scrollTo(0, 120), 800);

    // simulate initial Ajax
    setTimeout(() => {
      this.fetchData();
    }, 600);
  }

  componentWillUnmount() {
    clearInterval(this.state)
  }

  fetchData() {
        
        const url = window.BPdata.apiUrl+'/news?limit='+NUM_ROWS+'&start=10&featured=0';

        axios.get(url, {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "no-cors", // no-cors, cors, *same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
          headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
          },
          redirect: "follow", // manual, *follow, error
          referrer: "no-referrer", // no-referrer, *client
          //body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
        .then(resp => {
          // console.log(this.data.concat(resp.data));
          this.data = resp.data;
          //let resp_data = resp.data;

          //console.log(JSON.parse(resp_data));
          // foreleaseEventsr(var i in resp_data) {
          //   this.data.push(resp_data[i]);
          // }

          //let this.key = this.data.length;
          // console.log("-----------------");
          // console.log("----lenght---"+this.data.length)


          // console.log("-----------------");
            
            
            this.setState({
              dataSource: this.state.dataSource.cloneWithRows(this.data.reverse()),
              isLoading: false,
            });

            // this.data = { ...this.data, ...genData(++this.pageIndex) };
            // console.log(this.data)
            NUM_ROWS = Number(NUM_ROWS) + Number(LIMIT_ROWS);
            
        })
        .catch(function(error) {
          // console.log(error);
        });   
    // fetch(REQUEST_URL)
    //   .then((response) => response.json())
    //   .then((responseData) => {
    //     this.setState({
    //       dataSource: this.state.dataSource.cloneWithRows(responseData[0].data),
    //     });
    //   })
    //   .done();
  }

  //componentDidMount() {
    // you can scroll to the specified position
    // setTimeout(() => this.lv.scrollTo(0, 120), 800);

    // simulate initial Ajax
    // setTimeout(() => {
    //     console.log("ok");
    //     this.rData = genData();
    //     console.log(this.rData)
    //     // this.setState({
    //     //   dataSource: this.state.dataSource.cloneWithRows(this.rData),
    //     //   isLoading: false,
    //     // });

    //     //console.log(this.state)
    // }, 600);
  //}

  // If you use redux, the data maybe at props, you need use `componentWillReceiveProps`
  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.dataSource !== this.props.dataSource) {
  //     this.setState({
  //       dataSource: this.state.dataSource.cloneWithRows(nextProps.dataSource),
  //     });
  //   }
  // }

  onEndReached = (event) => {
    // load new data
    // hasMore: from backend data, indicates whether it is the last page, here is false
    if (this.state.isLoading && !this.state.hasMore) {
      
      return;
    }
    // console.log('reach end', event);
    //this.setState({ isLoading: true });
    if(TOTAL_INDEX !== 0) { 
      if(this.pageIndex+1 >= TOTAL_INDEX) {
        this.setState({
          isLoading: false,
        });
      } else {
        this.setState({ isLoading: true });
      }
    } else {
      this.setState({ isLoading: false });
    }

    setTimeout(() => {
      console.log(this.pageIndex)
      if(TOTAL_INDEX !== 0) {
  
        if(this.pageIndex+1 >= TOTAL_INDEX) {
          return ;
        }

        //this.data = { ...this.data, ...genData(++this.pageIndex) };
        
        this.fetchData();
        // this.setState({
        //   // dataSource: this.state.dataSource.cloneWithRows(this.rData),
        //   isLoading: false,
        // });
      } else {
        this.data = { ...this.data, ...genData(++this.pageIndex) };
        this.fetchData();
        // this.setState({
        //   dataSource: this.state.dataSource.cloneWithRows(this.rData),
        //   isLoading: false,
        // });
      }
      
    }, 1000);
  }

  render() {
    const separator = (sectionID, rowID) => (
      <div
        key={`${sectionID}-${rowID}`}
        style={{
          backgroundColor: '#F5F5F9',
          height: 8,
          borderTop: '1px solid #ECECED',
          borderBottom: '1px solid #ECECED',
        }}
      />
    );
    let index = this.data.length - 1;
    const row = (rowData, sectionID, rowID) => {
      if (index < 0) {
        index = this.data.length - 1;
      }
      const obj = this.data[index--];
      return (
        <Link to={"/news/"+obj.id_news+"/"+encodeURI(obj.news_name)}>
        <div key={rowID} style={{ padding: '0 5px' }}>
          {/* <div
            style={{
              lineHeight: '50px',
              color: '#888',
              fontSize: 18,
              borderBottom: '1px solid #F6F6F6',
            }}
          >{obj.title}</div> */}
          
        </div>
        </Link>
      );
    };
    return (
      <div className="page">
        <div className="page-content">
          <div className="main-content">
            <ListView
              ref={el => this.lv = el}
              dataSource={this.state.dataSource}
              renderHeader={() => <div className="list-header"><Link to="/latest"><img src="/img/latest.jpg" className="list-img-circle" alt="Latest News" /><span>LATEST NEWS</span></Link></div>}
              renderFooter={() => (<div style={{ padding: this.state.isLoading ? 30 : 0 , textAlign: 'center', display: this.state.isLoading ? 'block' : 'none' }}>
                {this.state.isLoading ? 'Loading...' : ''}
              </div>)}
              renderRow={row}
              renderSeparator={separator}
              className="am-list"
              pageSize={4}
              useBodyScroll
              // onScroll={() => { console.log('scroll'); }}
              scrollRenderAheadDistance={500}
              onEndReached={this.onEndReached}
              onEndReachedThreshold={10}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ListPage;

