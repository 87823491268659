/* eslint no-dupe-keys: 0 */
import React from 'react';
import { ListView,Grid } from 'antd-mobile';

// import { FacebookProvider, CommentsCount } from 'react-facebook';

import {
  Link
} from 'react-router-dom';

import axios from 'axios';

var moment = require('moment');

let NUM_ROWS = 100;
let LIMIT_ROWS = 100;
let TOTAL_INDEX = 0;
//let MAX_LENGTH = 90;
let MAX_TITLE_LENGTH = 50;
let MAX_LENGTH = 90;
let FIRST_MAX_LENGTH = 400;

function genData(pIndex = 0) {
  const dataBlob = {};
  for (let i = 0; i < NUM_ROWS; i++) {
    const ii = (pIndex * NUM_ROWS) + i;
    dataBlob[`${ii}`] = `row - ${ii}`;
  }
  return dataBlob;
}

class HabaIconList extends React.Component {
  constructor(props) {
    super(props);

    

    var dataSource = new ListView.DataSource({
      rowHasChanged: (r1, r2) => r1 !== r2
    });

    this.data = [];
    this.state = {
      
      dataSource: dataSource.cloneWithRows(this.data),
      news      : [],
      all_games : [
        
        {
            "brand_game_id": "08e2a834-1836-416d-bc22-3d15a412380f",
            "name": "Shaolin Fortunes 100",
            "key_name": "SGShaolinFortunes100",
            "line_desc": "100 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        }
      ],
      isLoading: false,
      advertise: []
      
    };

    this.pageIndex = 1;
    TOTAL_INDEX = 0;
    NUM_ROWS = 20;
    if(this.props.perPage) {
      NUM_ROWS = this.props.perPage;
      TOTAL_INDEX = this.props.pageIndexTotal;
      //console.log(TOTAL_INDEX)
      this.state.isLoading = false;
      
    }
  }

  componentDidMount() {

    if(localStorage.getItem('home_advertise')) {
        
      if(JSON.parse(localStorage.getItem("home_advertise")) != null) {
        let home_advertise = JSON.parse(localStorage.getItem("home_advertise"));
        this.setState({advertise: home_advertise})
      }
    } 

    if(localStorage.getItem('all_games')) {
      if(JSON.parse(localStorage.getItem("all_games")) != null) {
        let all_games = JSON.parse(localStorage.getItem("all_games"));
        this.setState({all_games: all_games})
        
      }
    } 

    // localStorage.setItem("all_games", JSON.stringify(resp.data.all_games) )

    this.fetchData();

    //TOTAL_INDEX = 20;
    // you can scroll to the specified position
    // setTimeout(() => this.lv.scrollTo(0, 120), 800);

    // // simulate initial Ajax
    // setTimeout(() => {
    //   this.fetchData();
    // }, 600);

  }

  componentWillUnmount() {
    // clearInterval(this.state)
  }

  fetchData() {
        
        //const url = window.BPdata.apiUrl+'/news?limit='+NUM_ROWS+'&start=10&featured=0';
        const url = window.BPdata.apiUrlCMS+"/news";

        axios.get(url, {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "no-cors", // no-cors, cors, *same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          //credentials: "same-origin", // include, *same-origin, omit
          crossDomain: true,
          headers: {
              "Content-Type": "application/json; charset=utf-8",
              "X-Trident-Token" : window.BPdata.token,
              // "Content-Type": "application/x-www-form-urlencoded",
          },
          redirect: "follow", // manual, *follow, error
          referrer: "no-referrer", // no-referrer, *client
          //body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
        .then(resp => {
          // console.log(this.data.concat(resp.data));
            
          // console.log(resp.data.all_games);
          this.all_games = resp.data.all_games;
          // this.data = resp.data.news.data;
          // console.log("-----------------");
            
            this.setState({
              // news      : this.data,
              all_games: this.all_games,
              // dataSource: this.state.dataSource.cloneWithRows(this.data.reverse()),
              isLoading: false,
              // advertise: resp.data.advertise
            });

            // localStorage.setItem("latest_news", JSON.stringify(resp.data.news) )

            localStorage.setItem("home_advertise", JSON.stringify(resp.data.advertise) )

            //NUM_ROWS = Number(NUM_ROWS) + Number(LIMIT_ROWS);

            //console.log(this.state.advertise)
            
        })
        .catch(function(error) {
          //console.log(error);
        });   
  }


  render() {
    // const separator = (sectionID, rowID) => (
    //   <div
    //     key={`${sectionID}-${rowID}`}
    //     style={{
    //       backgroundColor: '#F5F5F9',
    //       height: 8,
    //       borderTop: '1px solid #ECECED',
    //       borderBottom: '1px solid #ECECED',
    //     }}
    //   />
    // );
    // let index = this.data.length - 1;
    // let ui;
    // let adcount = 0;
  
    return (
      <div className="page" >

      <div className="row">
        <br />
        <div className="grid-100 text-center" style={{  paddingBottom: "40px"}}>
          <h2>{window.lang.title.slot_game}</h2>
        </div>
      </div>
                <div className="row text-center homeiconlist">
                  
                    <div className="grids">
                      
                      <Grid data={this.state.all_games}
                          columnNum={3}
                          renderItem={dataItem => (
                            (dataItem.game_type_name == "WSS" ) ? <Link to={'/wss-login'} className="cont-wrap-link" style={{  width: '95%'}}>
                            <div style={{ color: 'rgb(5 117 5)', fontSize: '14px', marginTop: '10px',textAlign: 'center',   width: "100%",  marginBottom: '0px', minHeight: "170px" }}>
                              {(dataItem.featured_img) ? <img src={'/img/homeicon/habanero/'+dataItem.featured_img} className="thumbnail-icon img-responsive opona"/> : <img src={'/img/homeicon/habanero/ISBAliceAdventure.png'} className="thumbnail-icon img-responsive opona"/>}
                              
                              <span className="sportsbook " style={{ width: "100%", fontWeight: "bold", fontSize: "13px" }}>{dataItem.name}</span>
                              
                            </div>
                            
                        </Link>:
                        <Link to={'/funky-login/'+dataItem.brand_game_id} className="cont-wrap-link" style={{  width: '95%'}}>
                        <div style={{ color: 'rgb(5 117 5)', fontSize: '14px', marginTop: '10px',textAlign: 'center',   width: "100%",  marginBottom: '0px', minHeight: "170px" }}>
                          {(dataItem.featured_img) ? <img src={'/img/homeicon/habanero/'+dataItem.featured_img} className="thumbnail-icon img-responsive opona"/> : <img src={'/img/homeicon/habanero/ISBAliceAdventure.png'} className="thumbnail-icon img-responsive opona"/>}
                          
                          <span className="sportsbook " style={{ width: "100%", fontWeight: "bold", fontSize: "13px" }}>{dataItem.name}</span>
                          
                        </div>
                        
                    </Link>
                          )} 
                        />
                    </div>
                  
                </div>

                <br />
            </div>

     
    );
  }
}

export default HabaIconList;

