import React from 'react';
import axios from 'axios';
import {
    Link
  } from 'react-router-dom';

import { Modal,ListView,Grid } from 'antd-mobile';
import AppHeader from './../AppHeader';



//var moment = require('moment');
require('./../../../BPdata.js')
const alert = Modal.alert;

class TopThreePage extends React.Component {
    constructor(props) {
        super(props)

        window.BPdata.footerMenu = "leaderboard";
        this.state = { 
            selectedDays: [new Date()],
            status: 1,
            step: "login", 
            message : "", 
            phone : "",
            league: [],
            advertise: {},
            innerData:{},
            contests : 
                {
                    id_gcontest :	0,
                    event_name	: "",
                    rule_regulation	: "",
                    created_at      : "",
                    start_date	    : "",
                    expire_date	    : "",
                }
            ,
            data            : [],
            isLoading       : true,
            top             : {}
        };
        this.checkLeaderboardSup         = this.checkLeaderboardSup.bind(this);
        //this.handleInputChange = this.handleInputChange.bind(this);
    }

    checkLeaderboardSup(id) {
        //console.log("ID --------- "+ id)
        //console.log(this.state.step);

        // alert(id)
     
        alert('10 Gems ကျသင့်မှာ ဖြစ်ပါသည်', '၀ယ်ယူမလား မ၀ယ်ဘူးလား', [
            { text: 'မ၀ယ်ဘူး', onPress: () => console.log('cancel'+id) },
            {
            text: '၀ယ်မယ်',
            onPress: () => {
                    // localStorage.setItem(window.BPdata.time, 0);
                    // localStorage.setItem('username', "");
                    // this.setState({ step: "login" , message : "Successfully Log Out" }); 
                    // //this.profileBlock()
                    // //this.viewHandler();
                    this.props.history.push("/participant/predict/order/"+id);
                }
                
            },
        ])

    }

    // checkLeaderboardSup() {
    //     //console.log(this.state.step);
    //     let data = window.confirm("Have you finished participant competition subscription?");
    //     if(data){
    //         console.log("ok");
    //     }
    // }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } 
           if(menu.length > 0) {
              //console.log(menu[menu.length - 1] + "-" + url )
                if(menu[menu.length - 2] === url ){
                    //console.log("first");
                    menu.pop();
                    //window.BPdata.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                    //localStorage.setItem("route", JSON.stringify(menu) );
                }  
                else if(menu[menu.length-1] !== url ){
                    menu.push(url)
                    //window.BPdata.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                }
                window.BPdata.preurl =  menu[menu.length-2];
            }
        }
    }

    fetchData(url,data) {
        
        // let phone = localStorage.getItem(window.BPdata.time)  

        // console.log(url+"----")
        let dataurl = url;

        let self = this;
        
        axios.get(dataurl, {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "no-cors", // no-cors, cors, *same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          //credentials: "same-origin", // include, *same-origin, omit
          crossDomain: true,
          headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
          },
          redirect: "follow", // manual, *follow, error
          referrer: "no-referrer", // no-referrer, *client
          //body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
        .then(resp => {
            this.setState({
                top         : resp.data,
                // bottom      : resp.data.bottom.data,
                // status      : resp.data.top.data.status,
                // advertise   : resp.data.advertise.data,
            }); 
            console.log(resp.data)
            // console.log( resp.data.top.data)
            // this.state.isLoading = false;
            
        })
        .catch(function(error) {
        //   console.log(error.tostring());
        });   

        //console.log(this.state.contests);
 
    }

    displayBlock1( ) {
        let data = this.state.top
        
        // console.log(data)
        let ui = []
        let id = 0;
        let datal = data.length;
        for (let i = 0; i < datal; i++) {
            
            let each = data[i]
            let datatwo = each.data
            ui.push(
                <div >  
                    
                     <div className="topthree" >
                         <div>
                            <span>{each.contest_id}</span> Week Winner List
                        
                            <hr />
                            {
                               
                                datatwo.map(d=>{
                                    return(
                                        <>
                                        
                                            <div className='flex-container'>
                                           
                                                <div className='flex-item-left'>{d.first_name}</div>
                                                <div className='flex-item-left'>{d.phone}</div>
                                                <div className='flex-item-left'>{this.dateFormater(d.created_at)}</div>

                                            </div>
                                        </>
                                    )
                                })
                            }
                         </div>
                     </div>
                    

                 </div>
            )
     
            
            
        }
        
        
            
       
        
        return ui;
        
    }

  
    dateFormater(data){
        const date = new Date(data);
        const formattedDate = date.toLocaleString();

        return formattedDate
    }
    childui(id,data) {
        let ui = []
        // console.log(id)
            //console.log("-------------"+data)
        
            if(Array.isArray(data) === false) {
                // console.log(data)
                // console.log(this.state.contests)
            } else {

                let arlength = data.length;
                
                if(arlength  > 0){
                    let ui = []
                    // data.forEach(function(each){
                    for (let i = 0; i < arlength; i++) { 
                        let each = data[i]
                        
                        //check handicap and change setting
                        
                        // ui.push(<tr className='league__table__design' key={"a"+i}>
                        //         <td><b>{i+1}</b></td>
                        //         <td colSpan="3" className="text-center pointer " >
                        //             {/* function need to add */}
                        //             {/* <b  className="color-green" onClick={this.checkLeaderboardSup.bind(this, each.id)} >
                        //                 {each.username}
                        //             </b> */}
                                    
                        //             {/* <Link to={"/more/account/"+each.id}  className="color-green">
                        //                 {each.username}
                        //             </Link>  */}
                        //             <Link to={"/participant/predict/order/"+each.customer.id}  className="color" style={{ Color:"#152e83" }}>
                        //                 {each.customer.first_name}
                        //             </Link> 
                        //             {/* <b className="color-green" >{each.username}</b> */}
                        //             {/* <b className="color-green" onClick={() => this.checkLeaderboardSup(each.id)} >{each.username}</b> */}
                        //         </td>
                        //         <td colSpan="2" className="text-center">
                        //             {each.customer.phone}
                        //         </td>
                        //     </tr>) 
                        ui.push(
                            <div className="flex-container" key={"a"+i}>
                                <div className="flex-item-left">
                                    {i+1}
                                </div>
                                <div className="flex-item-right">
                                    <Link to={"/participant/predict/order/"+each.customer.id}  className="" style={{ color:"#152e83" }}>
                                        {each.customer.first_name}
                                   </Link> 
                                </div>
                                <div className="flex-item-right">
                                {each.customer.phone}
                                </div>
                            </div>
                        )

                        
                }

                if(ui.length === 0) {
                    //this.setState({status : 1});
                        ui.push(<div className="text-center" key="0"><h2>There is no event today</h2></div>)
                }
                    return ui
                }
        }

        // console.log(status+"-------")
        // if(status === 1) {
        //     console.log(status+"-------")
        //     this.setState({status : 0});
        // }
        
        return ui;
    }
    

    //childui


    sendDailyBonusApi() {
        
        var self = this;
        if(localStorage.getItem(window.BPdata.time) !== 0) {
            // this.setState({
            //     phone:  localStorage.getItem(window.BPdata.time)  
            // })
            let dataurl = window.BPdata.apiUrl+'/user/dailybonus';
            // let contests = this.state;
            let contests = {
                phone:  localStorage.getItem(window.BPdata.time)  
            };
            axios({
                method: 'post',
                url: dataurl,
                data: contests,
                headers: { 'content-type': 'application/x-www-form-urlencoded' },
            })
            .then(function (response) {
                if(response.data.success) {
                    self.setState({  
                        message : response.data.message, 
                        status  : 1,
                        check: response.data.check
                    });

                    // let stateday = moment(self.state.selectedDays[0]).format("YYYY-MM-DD");
                    // let phone = localStorage.getItem(window.BPdata.time) ;
                    // self.fetchData(window.BPdata.apiUrl+'/user/league-contests?phone='+phone+'&filter='+stateday,'livescore');
                     
                    //self.setState({  message : "Successfully updated" });
                } else {
                    self.setState({  
                        message : "Something was wrong",
                     });
                }
                //console.log(response.data.success);
            })
            .catch(function (error) {
                // console.log(error);
            });
        }
        
    }
    
    componentDidMount() {
        if(localStorage.getItem(window.BPdata.time)) {
            if(localStorage.getItem(window.BPdata.time) !== "0" ) {
              this.setState({ 
                  step: "profile"
                }); 
            }
        } 
        
        // this.hisUrl('/more/league-table');
        // let stateday = moment(this.state.selectedDays[0]).format("YYYY-MM-DD");
        // let phone = localStorage.getItem(window.BPdata.time) ;
        this.fetchData(window.BPdata.apiUrl+'/user/topthreelist','livescore');
        this.state.isLoading = false
        console.log('ook ----')
        //this.fetchData();
        //localStorage.setItem('route', '/more/login');
        //console.log(localStorage.getItem('route'))
    }

    // componentWillUpdate() {
    //     localStorage.setItem('route', '/more/login');
    //     console.log(localStorage.getItem('route'))
    // }

    render() {
        return (
            <div className="page">
                <AppHeader titleType="2" title={window.lang.title.service} isLoading="false" />

                <div className="page-content text-center">
                    <div className="main-content">
                        <div className="row text-left">
                            <br/>
                            <ul className="secondsubmenu">
                                <li>
                                    <Link to="/more/league" className="btn-success btn " >
                                        {window.lang.title.leaderboard}  
                                    </Link> 
                                </li>
                                <li>
                                    <Link to="/more/topthree" className="btn-success btn color-gold" >
                                        {window.lang.title.history}  
                                    </Link> 
                                </li>
                            </ul>
                            
                        </div>
                        <div className="row">
                            <span>{this.state.message}</span>
                            <br />
                            { (this.state.isLoading) ?  (<div className="row text-center"><img src="/img/loading.gif" alt="loading" height="30px" /></div> ) : (<div className=''>
                               { this.displayBlock1()}
                            </div>) }
                            <br />
                            {/* {
                                (this.state.advertise) ? <div className="row">
                                <div className="grid">
                                    <a href={this.state.advertise.advertise_goto} target="_blank" rel="noopener noreferrer">
                                        <img src={window.BPdata.apiAssetUrl+"/images/"+this.state.advertise.advertise_image} style={{ width: '100%' }} alt={this.state.advertise.advertise_title}/>
                                    </a><br/><br/>
                                </div>
                                </div>: ""
                            }
                            <br /> */}
                            {/* { (this.state.isLoading) ?  (<div className="row text-center"><img src="/img/loading.gif" alt="loading"  /> </div> ) : this.displayBlock2() }
                         */}
                        </div>
                        
                        {/* <div className="ant-back-footer">
                            <div className="ant-back-top">
                                <div className="ant-back-top-content">
                                    <Link to={window.BPdata.preurl} className="color-green" >
                                { (JSON.parse(localStorage.getItem("route")) !== null) 
                                ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                                </Link> 
                                </div>
                            </div>
                        </div> */}
                     
                    </div>
                </div>

                <footer  className="App-footer">

                  <div className="App-footer-inner">
                    {/* {window.BPdata.footerMenu} */}
                    <Grid data={window.lang.footer}
                        columnNum={5}
                        renderItem={dataItem => (

                          <Link to={dataItem.url} className="cont-wrap-link hvr-pulse-shrink" style={{width: '100%'}}>
                                { (window.BPdata.footerMenu === dataItem.check )  ? (dataItem.text == "My Profile") ?  <span className="color-white"><img src={dataItem.icon}  height="25px"/><br />{(this.checklogin()) ? dataItem.text : "Login / Register" }</span> : <span className="text-white"><img src={dataItem.icon_off}  height="25px"/><br /><span >{dataItem.text}</span> </span> : <span className="text-white"><img src={dataItem.icon}  height="25px"/><br />{dataItem.text} </span> }

                          </Link>
                        )}
                      />

                  </div>
                </footer>
               
            </div>
        );
    }
}

export default TopThreePage;