window.avator = {
    "msg": "",
    "code": 0,
    "data": [
        // {
        //     "id": "1",
        //     "name": "Profile 1",
        //     "profile_photo": "profile_1.png",
        //     "line_desc": "",
        //     "dt_added": "2020-04-09 07:17:41",
        //     "dt_updated": "2020-04-09 07:17:41",
        // },
        {
            "id": "2",
            "name": "Profile 2",
            "profile_photo": "profile_2.png",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
        },
        {
            "id": "3",
            "name": "Profile 3",
            "profile_photo": "profile_3.png",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
        },
        {
            "id": "4",
            "name": "Profile 4",
            "profile_photo": "profile_4.png",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
        },
        {
            "id": "5",
            "name": "Profile 5",
            "profile_photo": "profile_5.png",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
        },
        {
            "id": "6",
            "name": "Profile 6",
            "profile_photo": "profile_6.png",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
        },
        {
            "id": "7",
            "name": "Profile 7",
            "profile_photo": "profile_7.png",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
        },
        {
            "id": "8",
            "name": "Profile 8",
            "profile_photo": "profile_8.png",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
        },
        {
            "id": "9",
            "name": "Profile 9",
            "profile_photo": "profile_9.png",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
        },
        {
            "id": "10",
            "name": "Profile 10",
            "profile_photo": "profile_10.png",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
        },
        {
            "id": "11",
            "name": "Profile 11",
            "profile_photo": "profile_11.png",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
        },
        {
            "id": "12",
            "name": "Boy 6",
            "profile_photo": "profile_boy6.png",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
        },
        {
            "id": "13",
            "name": "Boy 5",
            "profile_photo": "profile_boy5.png",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
        },
        {
            "id": "14",
            "name": "Boy 4",
            "profile_photo": "profile_boy4.png",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
        },
        {
            "id": "12",
            "name": "Boy 1",
            "profile_photo": "profile_boy1.png",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
        },
        {
            "id": "13",
            "name": "Boy 2",
            "profile_photo": "profile_boy2.png",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
        },
        {
            "id": "14",
            "name": "Boy 3",
            "profile_photo": "profile_boy3.png",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
        },
       
    ]
};
