/* eslint no-dupe-keys: 0 */
import React from 'react';
import Swiper from 'swiper/dist/js/swiper.min.js';

import {
  Link
} from 'react-router-dom';
import axios from 'axios';
import Modal from 'react-responsive-modal';
import { ListView,Grid } from 'antd-mobile';
import AppHeader from './AppHeader';
// import NewsVideoSlider from './../NewsVideoSlider';
//import HighlightsVideoSlider from './HighlightsVideoSlider';
// import HomeListPage from './HomeListPage';
import HabaIconList from './news/HabaIconList';

// import ScrollMenu from 'react-horizontal-scrolling-menu';
// import ScrollText from 'react-scroll-text'
//import { FacebookProvider, CommentsCount } from 'react-facebook';
//import { askForPermissioToReceiveNotifications } from './../push-notification';
require('./../../BPdata.js')

require('./../../Ghaba.js')



let data = [
  {
    news_image: 'https://zos.alipayobjects.com/rmsportal/dKbkpPXKfvZzWCM.png',
    news_name: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut consectetur temporibus eligendi fuga, dolor expedita ',
    news_desc: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut consectetur temporibus eligendi fuga, dolor expedita ',
  }
];


let NUM_ROWS = 3;
//let MAX_LENGTH = 90;
// One item component
// selected prop will be passed
const MenuItem = ({ activeMenu, data, text, selected }) => {
  return (
    
      <div
      className="menu-item"
      >
        {data.id === 0 ? (
          <Link to={`${data.url}`} className={(activeMenu === text) ? ' cont-wrap-link' : 'cont-wrap-link' } >{(window.BPdata.lang === 'mm')? data.mmshortname : data.shortname }</Link>
        ) : (
          <Link to={`/news/league/${data.id}/${data.url}`} className={(activeMenu === text) ? ' cont-wrap-link' : 'cont-wrap-link' } >{(window.BPdata.lang === 'mm')? data.mmshortname : data.shortname }</Link>
        )}
        {/* <Link to="/about" className={(activeMenu === text) ? 'active' : '' }  > */}
         
          {/* <span>{text}</span> */}

        {/* </Link> */}
      </div>
  );
};


// All items component
// Important! add unique key
export const Menu = (list,selected,activeMenu) => list.map(el => {
  const { name,shortname } = el;
  // console.log(selected)
  return (
    <MenuItem
      activeMenu={activeMenu}
      text={shortname}
      key={name}
      data={el}
    />
  );
});


const Arrow = ({ text, className }) => {
  return (
    <div
      className={className}
    >{text}</div>
  );
};


const ArrowLeft = Arrow({ text: '<', className: 'arrow-prev' });
const ArrowRight = Arrow({ text: '>', className: 'arrow-next' });


const closeIcon = (
  <svg fill="currentColor" viewBox="0 0 20 20" width={28} height={28}>
    <path
      fillRule="evenodd"
      d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
      clipRule="evenodd"
    ></path>
  </svg>
);


class HabaLoginHomePage extends React.Component {
  constructor(props) {
    super(props);
    window.BPdata.footerMenu = "home";
    const dataSource = new ListView.DataSource({
      rowHasChanged: (row1, row2) => row1 !== row2,
    });

    this.versionSwitch      = this.versionSwitch.bind(this);

    this.state = {
      selected: 0,
      dataSource,
      isLoading: true,
      // virtual data
      videoSlides:['slide1.jpg','slide1.jpg','slide1.jpg', 'slide1.jpg'],

      virtualVideoData: {
        slides: ['slide1.jpg','slide1.jpg','slide1.jpg', 'slide1.jpg'],
      },
      leagueMenu: 'NEWS',
      open: false,
      advopen : false,
      menu : window.BPdata.mainmenu,
    };


    
  }
  onSelect = key => {
    this.setState({ selected: key });
  }

  onOpenModal = () => {
    this.setState({ open: true,  advopen : true });
  };
 
  onCloseModal = () => {
    this.setState({ open: false , advopen : false });
    

    //localStorage.setItem('popup', false);
    // window.BPdata.popup = false
    // console.log(window.BPdata.popup)
  };

  versionSwitch() {
        if(localStorage.getItem('appview') === '0') {
            localStorage.setItem('appview', '1');
        } else {
            localStorage.setItem('appview', '0');
        }
        
        this.props.history.push("/");
    }

  componentDidMount() {
    let menu = ["/"];

    localStorage.setItem("route", JSON.stringify(menu) );
    //console.log(localStorage.getItem('route'));
    //console.log(window.BPdata.apiUrl)
    // you can scroll to the specified position
    // setTimeout(() => this.lv.scrollTo(0, 120), 800);

    

    const self = this;


    new Swiper('.swiper-video-container', {
      // ...
      loop: true,
      slidesPerView: 1,
      slidesPerGroup: 1,
      virtual: {
        slides: self.state.videoSlides,
        renderExternal(videoData) {
          // assign virtual slides data
          self.setState({
            virtualVideoData: videoData,
          });
        }
      },
      pagination: {
        el: '.swiper-vieo-pagination',
      },
    });

    
   

    // this.setState({ open: true }); 

      var d = new Date();
      var month = d.getDay();

      if(localStorage.getItem('popup')) {
        if(localStorage.getItem('popup') != month) {
          localStorage.setItem('popup', month);
          this.setState({ open: true }); 
        }
        
      } else {
        localStorage.setItem('popup', -1);
        this.setState({ open: true }); 
      }
      this.setState({ open: true }); 
      //console.log(localStorage.getItem('popup'));                            
      // this.fetchData();
      clearTimeout(window.BPdata.liveurl);
  }

  componentWillUnmount() {
    clearInterval(this.state)
  }

  UNSAFE_componentWillMount() {
    window.BPdata.lang = localStorage.getItem("lang");
  }

  fetchData() {
        
    const url = window.BPdata.apiUrl+'/news?limit='+NUM_ROWS+'&start=10&featured=1';

    axios.get(url, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "no-cors", // no-cors, cors, *same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      //credentials: "same-origin", // include, *same-origin, omit
      crossDomain: true,
      headers: {
          "Content-Type": "application/json; charset=utf-8",
          // "Content-Type": "application/x-www-form-urlencoded",
      },
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
      //body: JSON.stringify(data), // body data type must match "Content-Type" header
    })
    .then(resp => {
      // console.log(this.data.concat(resp.data));
      data = resp.data.news;
        
        this.setState({
          dataSource: this.state.dataSource.cloneWithRows(data.reverse()),
          isLoading: false,
          menu : resp.data.menu
        });

        // this.data = { ...this.data, ...genData(++this.pageIndex) };
        // console.log(this.data)
        NUM_ROWS = Number(NUM_ROWS) + Number(NUM_ROWS);
        // console.log(this.pageIndex)
        // lang = localStorage.getItem('lang');
        
      })
      .catch(function(error) {
        //console.log(error);
      });   
  }
  // If you use redux, the data maybe at props, you need use `componentWillReceiveProps`
  // componentWillReceiveProps(nextProps) {
  //   if (nextProps.dataSource !== this.props.dataSource) {
  //     this.setState({
  //       dataSource: this.state.dataSource.cloneWithRows(nextProps.dataSource),
  //     });
  //   }
  // }

  onEndReached = (event) => {
    // load new data
    // hasMore: from backend data, indicates whether it is the last page, here is false
    // if (this.state.isLoading && !this.state.hasMore) {
    //   return;
    // }
    // console.log('reach end', event);
    // this.setState({ isLoading: true });
    // setTimeout(() => {
    //   this.rData = { ...this.rData, ...genData(++pageIndex) };
    //   this.setState({
    //     dataSource: this.state.dataSource.cloneWithRows(this.rData),
    //     isLoading: false,
    //   });
    // }, 1000);
  }

  render() {
    const { selected } = this.state;
    let leagueMenu = this.state.leagueMenu;
    // Create menu from items
    const menu = Menu(this.state.menu, selected, leagueMenu);

    return (
      <div className="page">

                <AppHeader titleType="2" title={window.lang.title.service} isLoading="false" />

                <div className="page-content text-center">
                    <div className="main-content">


              <HabaIconList></HabaIconList>

              
              {/* <HighlightsVideoSlider></HighlightsVideoSlider> */}
             </div>

        </div>
      </div>
    );
  }
}

export default HabaLoginHomePage;