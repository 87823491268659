import React from 'react';
import axios from 'axios';
import {
    Link
  } from 'react-router-dom';
import StatusAlert, { StatusAlertService } from 'react-status-alert'
import 'react-status-alert/dist/status-alert.css'
import { Modal }  from 'antd-mobile';

var moment = require('moment');
const alert = Modal.alert;
// const shortalert = useAlert;

class UserPredictPackage extends React.Component {
    constructor(props) {
        super(props)
        this.state = { status: 0, step: "login", message : "", form : {username: '', phone: '',verify_code: '', password: '' },id:"",username:"",phone:"",created_at:"",league_points:"0",max_league_points:"0",rank:"" ,packages:[{"id":"1","name":"One Day","gem":"5","type":"1","bactive":"1"},{"id":"2","name":"One Month","gem":"100","type":"1","bactive":"1"}] , alertId: '' };
        // this.validatePhone      = this.validatePhone.bind(this);
       
        this.showSuccessAlert = this.showSuccessAlert.bind(this);
        this.removeAlert = this.removeAlert.bind(this);

        if(props.match.params.id) {
            this.state.id = props.match.params.id
        }
    }

    showSuccessAlert(message) {
        const alertId = StatusAlertService.showSuccess(message);
        this.setState({ alertId });
    }
    
    removeAlert() {
        StatusAlertService.removeAlert(this.state.alertId);
    }


    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } 
           if(menu.length > 0) {
              //console.log(menu[menu.length - 1] + "-" + url )
                if(menu[menu.length - 2] === url ){
                    //console.log("first");
                    menu.pop();
                    //window.BPdata.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                    //localStorage.setItem("route", JSON.stringify(menu) );
                }  
                else if(menu[menu.length-1] !== url ){
                    menu.push(url)
                    //window.BPdata.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                }
                window.BPdata.preurl =  menu[menu.length-2];
            }
        }
      }

    fetchUserData() {
        
        const url = window.BPdata.apiUrl+'/user/id/'+this.state.id+'?time='+localStorage.getItem(window.BPdata.time);
    
        axios.get(url, {
          method: "GET", // *GET, POST, PUT, DELETE, etc.
          mode: "no-cors", // no-cors, cors, *same-origin
          cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          credentials: "same-origin", // include, *same-origin, omit
          headers: {
              "Content-Type": "application/json; charset=utf-8",
              // "Content-Type": "application/x-www-form-urlencoded",
          },
          redirect: "follow", // manual, *follow, error
          referrer: "no-referrer", // no-referrer, *client
          //body: JSON.stringify(data), // body data type must match "Content-Type" header
        })
        .then(resp => {

            this.setState({
              id : resp.data.id,
              username : resp.data.username,
              phone : resp.data.phone,
              created_at : resp.data.created_at,
              league_points : resp.data.league_points,
              max_league_points : resp.data.max_league_points,
              rank : resp.data.rank
              
            });
            
          })
          .catch(function(error) {
            // console.log(error);
          });   
    }

    componentDidMount() {

        if(localStorage.getItem(window.BPdata.time)) {
            if(localStorage.getItem(window.BPdata.time) !== "0" ) {
              this.setState({ step: "profile" }); 
            }
        } 

        this.fetchUserData();

        this.sendPlayerPredictApi(0);

        this.hisUrl('/participant/predict/order/'+this.state.id);
        //localStorage.setItem('route', '/more/login');
        //console.log(localStorage.getItem('route'))
    }

    // componentWillUpdate() {
    //     localStorage.setItem('route', '/more/login');
    //     console.log(localStorage.getItem('route'))
    // }

    inputChangeHandler(e) {
        let form = {...this.state.form};
        form[e.target.name] = e.target.value;
        this.setState({
            form
        });
    }

    sendPlayerPredictApi(package_id) {
        // predictorder
        // gem amount and user token , player id require
        // $gem 		= $request['gem'];
		// $time 		= $request['time'];
        // $player_id 	= $request['player_id'];
        
        var self = this;
        let player_id = this.state.id;
        let salt = localStorage.getItem(window.BPdata.time);
        // var data = {package:packageid, player : playerid, salt : salt }

        // let dataurl = window.BPdata.apiUrl+'/participant/predict/order';
        let dataurl = window.BPdata.apiUrl+'/user/check';

        let data = {
            package_id : package_id,
            time:  salt,
            type : "predictorder" ,
            player_id: player_id
        };

        axios({
            method: 'post',
            url: dataurl,
            data: data,
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
        })
        .then(function (response) {
            // shortalert.show(response.data.message);
            self.setState({  
                message : response.data.message,
                status: response.data.status,
                packages : response.data.packages
            });

            if(response.data.message){
                self.showSuccessAlert(response.data.message )
            }
            
        })
        .catch(function (error) {
            // console.log(error);
        });
    }

    viewHandler() {
        if(this.state.step){
            switch(this.state.step) {
               // case "login":   return this.loginBlock();
                case "home":    return this.homeBlock();
                case "profile": return this.profileBlock();
                default:        return "";
              }
        }
       
    }

    predictpackagesui() {
        let ui = [];
        let arr = this.state.packages;
        let arlength = this.state.packages.length;
        
            if(arlength > 0) {
                
                for (let i = 0; i < arlength; i++) { 
                    let each = arr[i]
                    ui.push(
                            <div className="grid-40  bg-gray package-block" key={each}>
                                <img src="/img/tipster.png" alt="Weekly"/><br/>
                                <b className="color-green">{each.name}</b><br/>
                                <b className="color-red">{each.gem} Gem</b><br/>
                                <br/>
                                <button className="btn btn-success" onClick={() =>
                                    alert('Package', '၀ယ်ယူမလား မ၀ယ်ဘူးလား', [
                                    { text: 'မ၀ယ်ဘူး', onPress: () => console.log('cancel') },
                                    { text: '၀ယ်မယ်', onPress: () => this.sendPlayerPredictApi(each.id)  },
                                    ])
                                }>{window.lang.packages.buy}</button>
                    </div>)
                }
            }

        return ui;

    }


    render() {
        return (
            <div className="page">
                <div className="page-content text-left setting-menu">
                        
                        <table className="table">
                            <tr>
                                <td>User</td>
                                <td>{this.state.username}</td>
                            </tr>
                            <tr>
                                <td>ID</td>
                                <td>{this.state.id}</td>
                            </tr>
                            <tr>
                                <td>Point</td>
                                <td><span className="color-green">{this.state.league_points}</span></td>
                            </tr>
                            <tr>
                                <td>Highest Point</td>
                                <td><span className="color-green">{this.state.max_league_points}</span></td>
                            </tr>
                            <tr>
                                <td>Rank</td>
                                <td><span className="color-green">{this.state.rank}</span></td>
                            </tr>
                            <tr>
                                <td>Join Date</td>
                                <td>{moment(this.state.created_at, 'YYYY-MM-DD hh:mm:ss').format('DD MMM YYYY')}</td>
                            </tr>
                        </table>

                        {(this.state.message !== "") ? <div className="grid"><StatusAlert/></div>: ""}
                        
                        <br/>

            
                        { (this.state.status === 0 ) ? 
                        <div className="row text-center" >
                                { (localStorage.getItem(window.BPdata.time)) ? (localStorage.getItem(window.BPdata.time) !== "0" ) ? this.predictpackagesui() : "" : "" }
                        </div>
                         : "" }

                         {(this.state.status === 1 ) ?
                         <div className="page-content text-left setting-menu">
                            <ul>
                                <Link  to={"/more/account/"+this.state.id+"/bill-history"}><li>Bill History <span>&gt;</span></li></Link>
                                <Link  to={"/more/account/"+this.state.id+"/league-history"}><li>League History <span>&gt;</span></li></Link>
                            </ul>
                         </div>
                         : ""}


                        <div className="ant-back-footer">
                            <div className="ant-back-top">
                                <div className="ant-back-top-content">
                                    <Link to={window.BPdata.preurl} className="color-green" >
                                { (JSON.parse(localStorage.getItem("route")) !== null) 
                                ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                                </Link> 
                                </div>
                            </div>
                        </div>
                   
                </div>
               
            </div>
        );
    }
}

export default UserPredictPackage;