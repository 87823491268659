/* eslint no-dupe-keys: 0 */
import React from 'react';
import { ListView } from 'antd-mobile';

// import { FacebookProvider, CommentsCount } from 'react-facebook';

import {
  Link
} from 'react-router-dom';

import axios from 'axios';
var moment = require('moment');

let NUM_ROWS = 20;
let LIMIT_ROWS = 20;
let TOTAL_INDEX = 0;
//let MAX_LENGTH = 90;
let MAX_LENGTH = 90;

function genData(pIndex = 0) {
  const dataBlob = {};
  for (let i = 0; i < NUM_ROWS; i++) {
    const ii = (pIndex * NUM_ROWS) + i;
    dataBlob[`${ii}`] = `row - ${ii}`;
  }
  return dataBlob;
}

class ResultList2DPage extends React.Component {
  constructor(props) {
    super(props);

    window.BPdata.footerMenu = "home";

    var dataSource = new ListView.DataSource({
      rowHasChanged: (r1, r2) => r1 !== r2
    });

    this.data = [];
    this.state = {
      
      dataSource: dataSource.cloneWithRows(this.data),
      isLoading: true,
      advertise: []
      
    };

    this.pageIndex = 1;
    TOTAL_INDEX = 0;
    NUM_ROWS = 20;
    if(this.props.perPage) {
      NUM_ROWS = this.props.perPage;
      TOTAL_INDEX = this.props.pageIndexTotal;
      //console.log(TOTAL_INDEX)
      this.state.isLoading = true;
      
    }
  }

  componentDidMount() {

      window.scrollTo(0, 0);
    // if(localStorage.getItem('home_advertise')) {
        
    //   if(JSON.parse(localStorage.getItem("home_advertise")) != null) {
    //     let home_advertise = JSON.parse(localStorage.getItem("home_advertise"));
    //     this.setState({advertise: home_advertise})
    //   }
    // } 

    // if(JSON.parse(localStorage.getItem("videoSlides")) != null) {
    //   let video_slides = JSON.parse(localStorage.getItem("videoSlides"));
    //   this.setState({
    //     dataSource: this.state.dataSource.cloneWithRows(video_slides.reverse()),
    //     isLoading: true,
    //   });
    // }

    // if(localStorage.getItem('homeData')) {
    //   if(JSON.parse(localStorage.getItem("homeData")) != null) {
    //     let home_data = JSON.parse(localStorage.getItem("homeData"));
    //     this.setState({homeData: home_data})
    //     this.setState({
    //       dataSource: this.state.dataSource.cloneWithRows(home_data.dataSource),
    //       isLoading: false,
    //       advertise: home_data.advertise
    //     });
    //   }
    // } 
    //TOTAL_INDEX = 20;
    // you can scroll to the specified position
    setTimeout(() => this.lv.scrollTo(0, 120), 800);

    // simulate initial Ajax
    setTimeout(() => {
      this.fetchData();
    }, 600);
  }

  componentWillUnmount() {
    clearInterval(this.state)
  }

  fetchData() {
        
        let dataurl = window.BPdata.apiUrl+'/service/bet-list?limit='+NUM_ROWS+'&game_type=1';
        // let data = this.state.data;
        axios({
            method: 'get',
            url: dataurl,
            // data: data,
            headers: {
              "Content-Type": "application/json; charset=utf-8",
              "X-Trident-Token"  : window.BPdata.token,
              "BP-Agent"         : window.BPdata.rand_compound,
              "BP-Time"          : localStorage.getItem(window.BPdata.time)
              // "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(resp => {
          // console.log(this.data.concat(resp.data));
          this.bet_list = resp.data.bet_list.data;
          this.data = resp.data.bet_list.data;
          this.result_list = resp.data.result_list.data;
          // console.log("-----------------");
            
            this.setState({
              dataSource: this.state.dataSource.cloneWithRows(this.data.reverse()),
              isLoading: false
            });

            // localStorage.setItem("home_advertise", JSON.stringify(resp.data.advertise) )
            // localStorage.setItem("homeData", JSON.stringify({
            //   dataSource: this.data.reverse(),
            //   isLoading: false,
            //   advertise: resp.data.advertise
            // }) )

            // this.data = { ...this.data, ...genData(++this.pageIndex) };
            // console.log(this.data)
            NUM_ROWS = Number(NUM_ROWS) + Number(LIMIT_ROWS);

            //console.log(this.state.advertise)
            
        })
        .catch(function(error) {
          //console.log(error);
        });   
    // fetch(REQUEST_URL)
    //   .then((response) => response.json())
    //   .then((responseData) => {
    //     this.setState({
    //       dataSource: this.state.dataSource.cloneWithRows(responseData[0].data),
    //     });
    //   })
    //   .done();
  }


  onEndReached = (event) => {
    // load new data
    // hasMore: from backend data, indicates whether it is the last page, here is false
    if (this.state.isLoading && !this.state.hasMore) {
      
      return;
    }
    // console.log('reach end', event);
    //this.setState({ isLoading: true });
    if(TOTAL_INDEX !== 0) { 
      if(this.pageIndex+1 >= TOTAL_INDEX) {
        this.setState({
          isLoading: false,
        });
      } else {
        this.setState({ isLoading: true });
      }
    } else {
      this.setState({ isLoading: false });
    }

    setTimeout(() => {
      //console.log(this.pageIndex)
      if(TOTAL_INDEX !== 0) {
  
        if(this.pageIndex+1 >= TOTAL_INDEX) {
          return ;
        }

        //this.data = { ...this.data, ...genData(++this.pageIndex) };
        
        this.fetchData();
        // this.setState({
        //   // dataSource: this.state.dataSource.cloneWithRows(this.rData),
        //   isLoading: false,
        // });
      } else {
        this.data = { ...this.data, ...genData(++this.pageIndex) };
        this.fetchData();
        // this.setState({
        //   dataSource: this.state.dataSource.cloneWithRows(this.rData),
        //   isLoading: false,
        // });
      }
      
    }, 1000);
  }

  render() {
    const separator = (sectionID, rowID) => (
      <div
        key={`${sectionID}-${rowID}`}
        style={{
          backgroundColor: '#F5F5F9',
          height: 8,
          borderTop: '1px solid #ECECED',
          borderBottom: '1px solid #ECECED',
        }}
      />
    );
    let index = this.data.length - 1;
    let ui;
    let adcount = 0;
    const row = (rowData, sectionID, rowID) => {
      if (index < 0) {
        index = this.data.length - 1;
      }
      const obj = this.data[index--];

      ui =  <div style={{ display: '-webkit-box', display: 'flex', padding: '10px' }}>
               
                 
                  <div className="row p-4" style={{ lineHeight: 1 }}>
                        <Link to={"/more/bet-list/"+obj.invoice_no}>

                              <div className="text-left grid-60 left">
                                <b className="color-green">{moment(obj.created_at, 'YYYY-MM-DD hh:mm:ss').format('DD-MM-YYYY')}</b><br/>
                                  <span>{moment(obj.created_at, 'YYYY-MM-DD hh:mm:ss').format('hh:mm A')}</span>
                              </div>
                              <div className="text-left grid-40 right">
                                  <span>{obj.grand_total} MMK</span>
                              </div>

                        </Link>
                  </div>
                  
            </div>;
      
      return (
          ui
      );
    };
    return (
      <div className="page">
        <div className="page-content">
            <div className="main-content">

                <div className="row">
                  <Link to="/more/result-list-2d" className="color-red cont-wrap-link" >
                  <div className="grid-50   left text-center tab-holder">
                    Result
                  </div>
                  </Link>
                  <Link to="/more/bet-list-2d" className="color-red cont-wrap-link " >
                  <div className="grid-50 right text-center tab-holder active">
                    Bet List
                  </div>
                  </Link>
                </div>
                <div className="row text-left">

                  <ListView
                    ref={el => this.lv = el}
                    dataSource={this.state.dataSource}
                    renderHeader={() => ""}
                    renderFooter={() => (<div style={{ padding: this.state.isLoading ? 30 : 0 , textAlign: 'center', display: this.state.isLoading ? 'block' : 'none' }}>
                      {this.state.isLoading ? <img src="/img/loading.gif" alt="loading" height="50px"  /> : ''}
                    </div>)}
                    renderRow={row}
                    renderSeparator={separator}
                    className="am-list"
                    pageSize={10}
                    useBodyScroll
                    // onScroll={() => { console.log('scroll'); }}
                    scrollRenderAheadDistance={500}
                    onEndReached={this.onEndReached}
                    onEndReachedThreshold={10}
                  />
                </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ResultList2DPage;

