import React from 'react';
import axios from 'axios';
import { Grid } from 'antd-mobile';
import AppHeader from './../AppHeader';
import {
    Link
  } from 'react-router-dom';

  require('./../../../BPdata.js')

var moment = require('moment');
//var moment = require('moment');

class AnnouncementsPage extends React.Component {
    constructor(props) {
        super(props)
        window.BPdata.footerMenu = "promotions";
        this.state = {
            step: "login",
            message : "",
            data : [
                {id:    1, title : "Web App", type  : 0, description : "Web App version 1.1.2 is available now", link:"https://shwe-sports.com",
                    created_at : "2019-05-21 08:14:30" },
            ]
        };

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          }
           if(menu.length > 0) {
              //console.log(menu[menu.length - 1] + "-" + url )
                if(menu[menu.length - 2] === url ){
                    //console.log("first");
                    menu.pop();
                    //window.BPdata.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                    //localStorage.setItem("route", JSON.stringify(menu) );
                }
                else if(menu[menu.length-1] !== url ){
                    menu.push(url)
                    //window.BPdata.route = JSON.stringify(menu);
                    localStorage.setItem("route", JSON.stringify(menu) );
                }
                window.BPdata.preurl =  menu[menu.length-2];
            }
        }
      }

    getAnnouncementsApi() {
        var self = this;
        let dataurl = window.BPdata.apiUrlCMS+'/announcements';
        let data = this.state.data;
        axios({
            method: 'get',
            url: dataurl,
            data: data,
            headers: {
              "Content-Type": "application/json; charset=utf-8",
                "X-Trident-Token" : window.BPdata.token,
                "BP-Agent"         : window.BPdata.rand_compound
              // "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(function (response) {
            if(response.data.data) {
                self.setState({
                    data : response.data.data,
                });

                localStorage.setItem("noti", JSON.stringify(response.data.data) )
                //self.setState({  message : "Successfully updated" });
                //console.log(response.data.data)
            } else {
                self.setState({  message : "Something was wrong" });
            }
            //console.log(response.data.success);
        })
        .catch(function (error) {
            //console.log(error);
        });
    }



    handleInputChange(event) {
        const target = event.target;
        let value = target.type === 'radio' ? target.checked : target.value;
        let rev_value;
        if(value) {
            value     = 1;
            rev_value = 0;
        } else {
            value     = 0;
            rev_value = 1;
        }
        const name = target.name;
        let str = this.state.contests;
        // console.log(name)
        // console.log(name.split("-").length)

        if(name.split("-").length>0) {
            let word  = name.split("-");
            let first = word[0];
            let id    = word[1];
            console.log(first)
            if(first === "per_result1") {
                str[id].per_result1 = value;
                str[id].per_result2 = rev_value;
            }

            if(first === "per_result2") {
                str[id].per_result2 = value;
                str[id].per_result1 = rev_value;
            }

            if(first === "over") {
                str[id].over = value;
                str[id].under = rev_value;
            }

            if(first === "under") {
                str[id].under = value;
                str[id].over = rev_value;
            }

        }



        this.setState({contests:str});
        //console.log(name)
        // this.setState({
        //   [name]: value
        // });
        console.log(this.state.contests)
    }

    displayBlock( ) {
        let data = this.state.data
        //console.log(data.length)
        if(data.length  > 0){
            let ui = []
            // data.forEach(function(each){
            for (let i = 0; i < data.length; i++) {
                
                let each = data[i]
                ui.push(<div className="row"><div className="adm-card row grid-100 bg-noisee mb-0 box-paragraph">
                <div className="grid-20 left"><img src="/img/_verify.png" style={{ marginLeft: "20%" }} /></div><div className="grid-80 right">
                {each.description}<br/><sub><i>{moment(each.created_at, 'YYYY-MM-DD hh:mm:ss').format('DD MMMM YYYY, hh:mm A')}
                </i></sub></div></div></div>
               )


           }
           if(ui.length === 0) {
                ui.push(<div className="text-center" key="0"><h2>အချက်အလက်များရှာမတွေ့ပါ။</h2></div>)
           }
            return ui
        }
    }

    componentDidMount() {

        window.scrollTo(0, 0);

        // console.log(mmph.normalizeInput('+၉၅၉၇၈၄၁၂၃၄၅၆')); // returns 09784123456
        // console.log(mmph.normalizeInput('+959784123456')); // returns 09784123456
        // console.log(mmph.getTelecomName('09978412345')); // returns Ooredoo
        // console.log(mmph.getPhoneNetworkType('09978412345')); // returns GSM
        // console.log(mmph.isValidMMPhoneNumber('09978412345')); // returns true if it meets Myanmar Phone
        if(localStorage.getItem(window.BPdata.time)) {
            if(localStorage.getItem(window.BPdata.time) !== "0" ) {
              this.setState({ step: "profile" });
            }

          }

        if(localStorage.getItem('noti')) {
            if(JSON.parse(localStorage.getItem("noti")) != null) {
              let noti = JSON.parse(localStorage.getItem("noti"));
              this.setState({data: noti})

            }
        }
        // if(localStorage.getItem('username')) {
        //         this.setState({
        //             form: {
        //                 username : localStorage.getItem('username')
        //             }
        //         });
        // }

        // localStorage.setItem(window.BPdata.time, 0);
        // localStorage.setItem('username', "");
        // else {
        //   localStorage.setItem(window.BPdata.time, 'profile');
        //   this.setState({ step: "home" });
        // }
        this.hisUrl('/more/announcements');
        //localStorage.setItem('route', '/more/login');
        //console.log(localStorage.getItem('route'))
        this.getAnnouncementsApi();
    }

    // componentWillUpdate() {
    //     localStorage.setItem('route', '/more/login');
    //     console.log(localStorage.getItem('route'))
    // }

    render() {
        return (
            <div className="page">

                <AppHeader titleType="2" title={window.lang.title.promotions} isLoading="false" />
                <div className="page-content">
                    <div className="main-content text-center">

                        <div className="row text-left">
                            <div className="page-content">
                                <br />
                                <br/>
                                {this.displayBlock()}
                            </div>
                        </div>
                      {/*  <div className="ant-back-footer">
                            <div className="ant-back-top">
                                <div className="ant-back-top-content">
                                    <Link to={window.BPdata.preurl} className="color-green" >
                                { (JSON.parse(localStorage.getItem("route")) !== null)
                                ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                                </Link>
                                </div>
                            </div>
                        </div>*/}
                    </div>
                </div>
                <footer  className="App-footer">

                  <div className="App-footer-inner">
                    {/* {window.BPdata.footerMenu} */}
                    <Grid data={window.lang.footer}
                        columnNum={4}
                        renderItem={dataItem => (

                          <Link to={dataItem.url} className="cont-wrap-link hvr-pulse-shrink" style={{width: '100%'}}>
                                { (window.BPdata.footerMenu === dataItem.check )  ? (dataItem.text == "My Profile") ?  <span className="color-white"><img src={dataItem.icon}  height="25px"/><br />{(this.checklogin()) ? dataItem.text : "Login / Register" }</span> : <span className="text-white"><img src={dataItem.icon_off}  height="25px"/><br /><span >{dataItem.text}</span> </span> : <span className="text-white"><img src={dataItem.icon}  height="25px"/><br />{dataItem.text} </span> }

                          </Link>
                        )}
                      />

                  </div>
                </footer>
            </div>
        );
    }
}

export default AnnouncementsPage;
