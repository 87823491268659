import React from 'react';
import axios from 'axios';
import {
    Link
  } from 'react-router-dom';

import { Grid } from 'antd-mobile';
import AppHeader from './AppHeader';

require('./../../BPdata.js')

class ContactUsPage extends React.Component {
    constructor(props) {
        super(props)
        window.BPdata.footerMenu = "service";
        this.state = {
          viber_ph_1 : "959753564739",
          viber_ph_2 : "959753564739",
          step: "login", message : "", form : {username: '', phone: '',verify_code: '', password: '' }};
          
    }

    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          } else {
            if(menu[menu.length - 1] !== url ){
              menu.push(url)
              localStorage.setItem("route", JSON.stringify(menu) );
            }
          }

          // localStorage.setItem("route", JSON.stringify(menu) );
          // console.log(localStorage.getItem('route'));
        }
       // console.log(localStorage.getItem('route'));
    }

    componentDidMount() {
        window.scrollTo(0, 0);

      

        if(localStorage.getItem('sbsetting')) {
            if(localStorage.getItem('sbsetting') !== "0" ) {

                let sbsetting = JSON.parse(localStorage.getItem("sbsetting"));

                this.setState(prevState => ({
                    viber_ph_1 : sbsetting[0].option_value,
                    viber_ph_2 : sbsetting[1].option_value
                }))

                // console.log( sbsetting )

                return true;
            }
        }

    }

    // componentWillUpdate() {
    //     localStorage.setItem('route', '/more/login');
    //     console.log(localStorage.getItem('route'))
    // }

    inputChangeHandler(e) {
        let form = {...this.state.form};
        form[e.target.name] = e.target.value;
        this.setState({
            form
        });
    }


    viewHandler() {
        if(this.state.step){
            switch(this.state.step) {
                case "login":   return this.loginBlock();
                case "home":    return this.homeBlock();
                case "profile": return this.profileBlock();
                default:        return "";
              }
        }

    }


    render() {
        return (
            <div className="page">

                <AppHeader titleType="2" title={window.lang.title.service} isLoading="false" />

                <div className="page-content text-center">
                    <div className="main-content">

                        <div className="card row">
                            <div className="el-card__body">
                                <br />
                                <br />
                                <span>
                                    {window.lang.messages.title} <span className="color-theme-gold">{window.lang.messages.company}</span> <br />   {window.lang.messages.title_one}
                                </span>
                                <br />
                                <br />
                                <span>
                                  {window.lang.messages.day}

                                </span>
                                <br />
                                <br />

                                <div className="adm-card row grid-90 bg-contact">

                                        <div className="row" style={{ backgroundColor:"#152e83",padding:"10px",borderRadius:'10px',color:"white" }}>
                                        Viber {window.lang.messages.connect_one}
                                        </div>
                                    {/* <div className="adm-card-body row color-white" style={{ backgroundColor:"#152e83" }}>
                                    <div className="grid-100 left text-left pl-20" style={{ textAlign:"center" }}>
                                                Viber {window.lang.messages.connect_one}
                                              <br /><br /><br />
                                            </div>
                                        <div className="grid-90  pl-20 pt-4 pb-4">
                                          <hr style={{ "borderBottom": "1px solid #FFFFFF" , "opacity": "0.25" }} />
                                        </div>
                                    </div> */}

                                    {/* <div className="adm-card-body grid-100 pt-4 pb-4">

                                            <div className="grid-100 left text-left pl-20">
                                                Viber {window.lang.messages.connect_one}
                                              <br /><br /><br />
                                            </div>

                                    </div> */}

                                    <div className=" row content__box">
                                            <div className="phone__1">
                                                <a href={"viber://contact?number=%2B"+this.state.viber_ph_1} style={{  fontSize: "20px",fontFamily:"Arial"}} className="color-blue">
                                                    +{this.state.viber_ph_1}
                                                </a>
                                            </div>
                                            <div className="phone__2">
                                                <a href={"viber://contact?number=%2B"+this.state.viber_ph_1}  >
                                                <img src="/img/list/viber_group.png" alt="" height="30px"/>
                                                </a>

                                            </div>
                                                
                                              
                                    </div>
                                    <div className=" row content__box">
                                    <div className="phone__1">
                                    <a href={"viber://contact?number=%2B"+this.state.viber_ph_2}  style={{  fontSize: "20px",fontFamily:"Arial"}} className="color-blue">
                                                    +{this.state.viber_ph_2}
                                                </a>
                                            </div>
                                            <div className="phone__2">
                                            <a href={"viber://contact?number=%2B"+this.state.viber_ph_1}  >

                                                <img src="/img/list/viber_group.png" alt="" height="30px"/>
                                                </a>

                                            </div>

                                       

                                    </div>
                                </div>

                                <br />
                                <br />
                                <span>
                                  <Link to="/faq" className="color-blue">
                                    {window.lang.messages.company_one} {window.lang.messages.add_one}  <br /> {window.lang.messages.add_two}
                                  </Link>
                                </span>
                                <br />
                                <br />
                                <span>
                                  <Link to="/more/feedback" className="color-blue">
                                    {window.lang.more.feedback}
                                  </Link>
                                </span>
                                



{/*
                                <ul className="contact_phone">
                                    <div className="el-row">
                                        <div className="el-col el-col-9" style={{ textAlign: "left" }}>
                                        <li className="phone_item">+95977377xxxx</li>
                                        </div> <div className="el-col el-col-15" style={{ textAlign: "right" }}><li>
                                        <button type="button" className="el-button el-button--default el-button--medium"><span>
                                            <a href="viber://contact?number=%2B95977377xxxx" style={{ color: "rgb(123, 81, 156)", fontSize: "15px" }}>
                                            <img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0iIzdCNTE5QyIgd2lkdGg9IjE4cHgiIGhlaWdodD0iMThweCI+PHBhdGggZD0iTTAgMGgyNHYyNEgwVjB6IiBmaWxsPSJub25lIi8+PHBhdGggZD0iTTEyLjg4IDUuMDVjMy4xOC40IDUuNjcgMi44OSA2LjA3IDYuMDcuMDYuNTEuNDkuODguOTkuODguMDQgMCAuMDggMCAuMTItLjAxLjU1LS4wNy45NC0uNTcuODctMS4xMi0uNTEtNC4wOS0zLjcyLTcuMy03LjgxLTcuODEtLjU1LS4wNi0xLjA1LjMzLTEuMTEuODgtLjA3LjU1LjMyIDEuMDUuODcgMS4xMXptLjM4IDIuMTFjLS41My0uMTQtMS4wOC4xOC0xLjIyLjcycy4xOCAxLjA4LjcyIDEuMjJjMS4wNS4yNyAxLjg3IDEuMDkgMi4xNSAyLjE1LjEyLjQ1LjUyLjc1Ljk3Ljc1LjA4IDAgLjE3LS4wMS4yNS0uMDMuNTMtLjE0Ljg1LS42OS43Mi0xLjIyLS40Ny0xLjc3LTEuODQtMy4xNC0zLjU5LTMuNTl6bTUuOTcgOC4xbC0yLjU0LS4yOWMtLjYxLS4wNy0xLjIxLjE0LTEuNjQuNTdsLTEuODQgMS44NGMtMi44My0xLjQ0LTUuMTUtMy43NS02LjU5LTYuNTlsMS44NS0xLjg1Yy40My0uNDMuNjQtMS4wMy41Ny0xLjY0bC0uMjktMi41MmMtLjEyLTEuMDEtLjk3LTEuNzctMS45OS0xLjc3SDUuMDNjLTEuMTMgMC0yLjA3Ljk0LTIgMi4wNy41MyA4LjU0IDcuMzYgMTUuMzYgMTUuODkgMTUuODkgMS4xMy4wNyAyLjA3LS44NyAyLjA3LTJ2LTEuNzNjLjAxLTEuMDEtLjc1LTEuODYtMS43Ni0xLjk4eiIvPjwvc3ZnPg==" alt="" />Viber
                                        </a></span></button>
                                    </li>
                                    </div>
                                        </div>
                                        <div className="el-row"><div className="el-col el-col-9" style={{ textAlign: "left" }}><li className="phone_item">+95977377xxxx</li></div> <div className="el-col el-col-15" style={{ textAlign: "right" }}>
                                        <li><button type="button" className="el-button el-button--default el-button--medium"><span></span></button></li></div></div>
                                </ul>*/}
                            </div>
                        </div>
                    </div>
                </div>

                <footer  className="App-footer">

                  <div className="App-footer-inner">
                    {/* {window.BPdata.footerMenu} */}
                    <Grid data={window.lang.footer}
                        columnNum={4}
                        renderItem={dataItem => (

                          <Link to={dataItem.url} className="cont-wrap-link hvr-pulse-shrink" style={{width: '100%'}}>
                                { (window.BPdata.footerMenu === dataItem.check )  ? (dataItem.text == "My Profile") ?  <span className="color-white"><img src={dataItem.icon}  height="25px"/><br />{(this.checklogin()) ? dataItem.text : "Login / Register" }</span> : <span className="text-white"><img src={dataItem.icon_off}  height="25px"/><br /><span >{dataItem.text}</span> </span> : <span className="text-white"><img src={dataItem.icon}  height="25px"/><br />{dataItem.text} </span> }

                          </Link>
                        )}
                      />

                  </div>
                </footer>

            </div>
        );
    }
}

export default ContactUsPage;
