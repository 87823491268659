window.haba = {
    "msg": "",
    "code": 0,
    "data": [
        
        {
            "brand_game_id": "08e2a834-1836-416d-bc22-3d15a412380f",
            "name": "Shaolin Fortunes 100",
            "key_name": "SGShaolinFortunes100",
            "line_desc": "100 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "0b3f2ae4-fef8-47f9-8836-8c8bfff3fde6",
            "name": "Pucker Up Prince",
            "key_name": "SGPuckerUpPrince",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "0e04c998-a33a-4176-9388-699ae8de90b0",
            "name": "Wizards Want War",
            "key_name": "SGWizardsWantWar",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:42",
            "dt_updated": "2020-04-09 07:17:42",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "10405c09-6009-41bd-8b40-1fc1b1d44845",
            "name": "Colossal Gems",
            "key_name": "SGColossalGems",
            "line_desc": "30 Lines",
            "dt_added": "2020-04-09 07:17:42",
            "dt_updated": "2020-04-09 07:17:42",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "122669f9-14ab-416f-a58a-b9065437a85a",
            "name": "Queen of Queens",
            "key_name": "SGQueenOfQueens243",
            "line_desc": "243 Ways (30 Coins)",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "170031ed-1465-4ad8-be32-dc142359d343",
            "name": "Baccarat",
            "key_name": "AmericanBaccarat",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Baccarat"
        },
        {
            "brand_game_id": "1b49eb5d-8106-44a3-8ac3-f97eda1b06a0",
            "name": "The Grape Escape",
            "key_name": "SGGrapeEscape",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "1c266bb4-e252-46d5-96f7-b9cac1d9974a",
            "name": "Happy Ape",
            "key_name": "SGHappyApe",
            "line_desc": "",
            "dt_added": "2021-07-13 18:36:28",
            "dt_updated": "2021-07-13 18:36:28",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "1f549cf3-5de8-4471-a4d0-22e2affbc6a8",
            "name": "Disco Beats",
            "key_name": "SGDiscoBeats",
            "line_desc": "27 Ways (9 Coins)",
            "dt_added": "2022-03-28 16:57:34",
            "dt_updated": "2022-03-28 16:57:34",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "1fd7a8a5-545c-464a-9427-d5e7c9f12a69",
            "name": "Space Fortune",
            "key_name": "SGSpaceFortune",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "216fac36-85da-4cbf-921f-f89003d85ad6",
            "name": "Lantern Luck",
            "key_name": "SGLanternLuck",
            "line_desc": "25 Lines",
            "dt_added": "2022-02-21 10:45:44",
            "dt_updated": "2022-02-21 10:45:44",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "21fb4b5a-cb4b-4664-8ec8-995da6a6338f",
            "name": "Golden Unicorn Deluxe",
            "key_name": "SGGoldenUnicornDeluxe",
            "line_desc": "25 Lines",
            "dt_added": "2022-06-27 14:52:46",
            "dt_updated": "2022-06-27 14:52:46",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "23f0c4f5-c17e-472f-903e-aad41879995a",
            "name": "Scruffy Scallywags",
            "key_name": "SGScruffyScallywags",
            "line_desc": "30 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "259f822d-5543-4b85-a2d4-edc3c872164a",
            "name": "Golden Unicorn",
            "key_name": "SGGoldenUnicorn",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "27ae3a56-2eaa-4e68-8487-d243ccd1ca7a",
            "name": "Dragon Castle",
            "key_name": "SGTheDragonCastle",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "27c0b878-0c20-4471-92ab-15308be10dbb",
            "name": "Kane's Inferno",
            "key_name": "SGKanesInferno",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "27f6f818-fb5e-4ce8-a624-5d1326db68a2",
            "name": "Sky's the Limit",
            "key_name": "SGSkysTheLimit",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "28eb75d6-ab44-4d03-b2fb-aa218a3beee8",
            "name": "Taberna De Los Muertos Ultra",
            "key_name": "SGTabernaDeLosMuertosUltra",
            "line_desc": "",
            "dt_added": "2021-07-28 17:56:09",
            "dt_updated": "2021-07-28 17:56:09",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "29fcbb2a-21bd-446e-8b62-43e6cc7fb984",
            "name": "Calaveras Explosivas",
            "key_name": "SGCalaverasExplosivas",
            "line_desc": "Grouped Pays (15 Coins)",
            "dt_added": "2021-07-27 03:54:18",
            "dt_updated": "2021-07-27 03:54:18",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "2b30bd06-85e4-4917-8678-49c9522d3ecd",
            "name": "Koi Gate",
            "key_name": "SGTheKoiGate",
            "line_desc": "18 Lines",
            "dt_added": "2020-04-09 07:17:42",
            "dt_updated": "2020-04-09 07:17:42",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "2bcb11db-0bd1-45e3-889f-590fa0d7f1a2",
            "name": "Cashosaurus",
            "key_name": "SGCashosaurus",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "2e8c9b86-8744-4f34-a99e-2d26baef8b9d",
            "name": "Ways Of Fortune",
            "key_name": "SGWaysOfFortune",
            "line_desc": "576 Ways (28 Coins)",
            "dt_added": "2020-04-09 07:17:42",
            "dt_updated": "2020-04-09 07:17:42",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "2f1552f4-c808-4dde-b7bf-c2ab523a6e4a",
            "name": "Taberna De Los Muertos",
            "key_name": "SGTabernaDeLosMuertos",
            "line_desc": "",
            "dt_added": "2021-07-13 18:34:28",
            "dt_updated": "2021-07-13 18:34:28",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "315d6de4-702b-411a-9b80-8e53d77280c4",
            "name": "Indian Cash Catcher",
            "key_name": "SGIndianCashCatcher",
            "line_desc": "243 Ways (25 Coins)",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "348e6145-3c95-40d0-a489-b82c37bc083b",
            "name": "Tower Of Pizza",
            "key_name": "SGTowerOfPizza",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "36f115b2-4daf-452b-9f8d-3365bf639813",
            "name": "Hot Hot Halloween",
            "key_name": "SGHotHotHalloween",
            "line_desc": "Horizontal Pays (20 Coins)",
            "dt_added": "2020-04-09 07:17:42",
            "dt_updated": "2020-04-09 07:17:42",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "37989bbb-c4d5-4dd8-bd38-f87222a0a494",
            "name": "Wicked Witch",
            "key_name": "SGWickedWitch",
            "line_desc": "20 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "3ab6e554-e5db-4e8a-9226-ad0fd7e8f1a7",
            "name": "Panda Panda",
            "key_name": "SGPandaPanda",
            "line_desc": "243 Ways (30 Coins)",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "3bbe2a55-a039-4812-9822-dc50806de8e3",
            "name": "Nuwa",
            "key_name": "SGNuwa",
            "line_desc": "28 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "3befa0ea-641c-4bb1-bd09-003e5fb36aaf",
            "name": "Loony Blox",
            "key_name": "SGLoonyBlox",
            "line_desc": "243 Ways (25 Coins)",
            "dt_added": "2020-04-09 07:17:42",
            "dt_updated": "2020-04-09 07:17:42",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "3e9fa4e0-d8b8-41d3-9f88-992a475ee7a7",
            "name": "Galactic Cash",
            "key_name": "SGGalacticCash",
            "line_desc": "50 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "40a6c7c4-0f39-478f-97af-b3bf43eea600",
            "name": "Happiest Christmas Tree",
            "key_name": "SGHappiestChristmasTree",
            "line_desc": "40 Lines (40 Coins)",
            "dt_added": "2020-04-09 07:17:42",
            "dt_updated": "2020-04-09 07:17:42",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "40d5d837-5adb-4848-b8c3-213fdb3dd703",
            "name": "Cake Valley",
            "key_name": "SGCakeValley",
            "line_desc": "243 Ways (25 Coins)",
            "dt_added": "2020-04-09 07:17:42",
            "dt_updated": "2020-04-09 07:17:42",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "40de9f96-cc28-4e67-b2ce-651fcdb67089",
            "name": "Ocean's Call",
            "key_name": "SGOceansCall",
            "line_desc": "20 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "4260fd8f-d5a9-49a2-9de5-8bc027cbad8b",
            "name": "Coyote Crash",
            "key_name": "SGCoyoteCrash",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "455d79c1-dfb1-4ab0-bbe3-af03be5578bf",
            "name": "Super Twister",
            "key_name": "SGSuperTwister",
            "line_desc": "50 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "462f0ec7-2f1b-4f74-bfa9-0a31be71cfd4",
            "name": "5 Lucky Lions",
            "key_name": "SG5LuckyLions",
            "line_desc": "88 Lines",
            "dt_added": "2020-04-09 07:17:42",
            "dt_updated": "2020-04-09 07:17:42",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "4813e20b-fb90-4d2c-af4c-9472a5627bfe",
            "name": "Viking's Plunder",
            "key_name": "SGVikingsPlunder",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "49efd069-cf89-46ef-be8f-49fd5550bf33",
            "name": "Glam Rock",
            "key_name": "SGGlamRock",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "4ad201dd-6e8d-43ae-b938-a59e31058ddc",
            "name": "New Years Bash",
            "key_name": "SGNewYearsBash",
            "line_desc": "25 Lines",
            "dt_added": "2021-11-29 10:49:51",
            "dt_updated": "2021-11-29 10:49:51",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "4c81dddf-e095-4a32-a3e7-9af95debd034",
            "name": "Ride 'em Cowboy",
            "key_name": "SGRideEmCowboy",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "4c8c4515-269b-4c01-934a-722e15784c5d",
            "name": "Wild Trucks",
            "key_name": "SGWildTrucks",
            "line_desc": "15 Lines",
            "dt_added": "2020-04-09 07:17:42",
            "dt_updated": "2020-04-09 07:17:42",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "4e7661b8-76d5-4132-b587-3269e7389e82",
            "name": "Dragon's Throne",
            "key_name": "SGDragonsThrone",
            "line_desc": "50 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "4fcc0cd8-1d37-45dc-aa03-799e53fe29d6",
            "name": "Santa's Village",
            "key_name": "SGSantasVillage",
            "line_desc": "Horizontal Pays (15 Coins)",
            "dt_added": "2020-04-09 07:17:42",
            "dt_updated": "2020-04-09 07:17:42",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "512b1ed3-4c35-4b1f-ab13-78f5b9abd687",
            "name": "Bomb Runner",
            "key_name": "SGBombRunner",
            "line_desc": "707 Connected Ways (30 Coins)",
            "dt_added": "2022-05-30 14:06:56",
            "dt_updated": "2022-05-30 14:06:56",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "5164e4e7-9497-47db-9a5e-0d3843b4bb4a",
            "name": "Pirate's Plunder",
            "key_name": "SGPiratesPlunder",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "5183c190-5992-4066-b2fc-cd6e13a148bb",
            "name": "Little Green Money",
            "key_name": "SGLittleGreenMoney",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "56195df2-9879-4b4d-8849-15c022ef405b",
            "name": "Fa Cai Shen",
            "key_name": "SGFaCaiShen",
            "line_desc": "28 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "59c4c94a-97be-4ebe-8dff-5d424a8c04ba",
            "name": "Treasure Diver",
            "key_name": "SGTreasureDiver",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "5ceff391-e548-4984-9894-a439706482b1",
            "name": "Mighty Medusa",
            "key_name": "SGMightyMedusa",
            "line_desc": "466 Connected Ways (30 Coins)",
            "dt_added": "2022-04-25 15:42:45",
            "dt_updated": "2022-04-25 15:42:45",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "5de16f6c-4d9f-4e95-a0f6-513b682a96ac",
            "name": "Weird Science",
            "key_name": "SGWeirdScience",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "610486f1-45a7-4d09-8d4a-8f0ffc1748ed",
            "name": "Lucky Fortune Cat",
            "key_name": "SGLuckyFortuneCat",
            "line_desc": "28 Lines",
            "dt_added": "2020-04-28 04:26:06",
            "dt_updated": "2020-04-28 04:26:06",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "623717f5-c8b3-4cf8-8fc9-6bed81aea06b",
            "name": "Ruffled Up",
            "key_name": "SGRuffledUp",
            "line_desc": "243 Ways (25 Coins)",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "63d6d0a7-bf26-49ea-b0c8-6ffd1fc5c78e",
            "name": "All For One",
            "key_name": "SGAllForOne",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "64708ac9-6ed2-43ab-b3b3-21c13b4412d9",
            "name": "Egyptian Dreams Deluxe",
            "key_name": "SGEgyptianDreamsDeluxe",
            "line_desc": "243 - 3125 Ways (25 Coins)",
            "dt_added": "2020-04-09 07:17:42",
            "dt_updated": "2020-04-09 07:17:42",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "647f227c-0ab3-42d8-8d28-1623fbaaa496",
            "name": "Nine Tails",
            "key_name": "SGNineTails",
            "line_desc": "259 Connected Ways (30 Coins)",
            "dt_added": "2021-10-25 16:31:11",
            "dt_updated": "2021-10-25 16:31:11",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "65441e1f-a345-46d4-8699-2d14299e2b5f",
            "name": "Fa Cai Shen Deluxe",
            "key_name": "SGFaCaiShenDeluxe",
            "line_desc": "Horizontal & Vertical Pays (28 Coins)",
            "dt_added": "2020-04-09 07:17:42",
            "dt_updated": "2020-04-09 07:17:42",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "657d59e9-bf4c-4fa9-9fdf-bf2d2808a127",
            "name": "12 Zodiacs",
            "key_name": "SG12Zodiacs",
            "line_desc": "18 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "66a0f5cf-9e18-46ea-91b6-9911196a774c",
            "name": "Mount Mazuma",
            "key_name": "SGMountMazuma",
            "line_desc": "243 Super Ways (20 Coins)",
            "dt_added": "2020-04-09 07:17:42",
            "dt_updated": "2020-04-09 07:17:42",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "68c642b7-695b-44c7-81b6-7694be8939fc",
            "name": "Rodeo Drive",
            "key_name": "SGRodeoDrive",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "6ade878f-f6c2-4811-a6c8-1a2d61596d52",
            "name": "Jellyfish Flow",
            "key_name": "SGJellyfishFlow",
            "line_desc": "27 Ways (15 Coins)",
            "dt_added": "2020-08-25 04:58:08",
            "dt_updated": "2020-08-25 04:58:08",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "6ccb5798-9579-416c-986b-6f89ed0159fe",
            "name": "Shaolin Fortunes",
            "key_name": "SGShaolinFortunes243",
            "line_desc": "243 Ways (25 Coins)",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "6d62b88d-f37a-4933-851e-a604ff620583",
            "name": "Fly!",
            "key_name": "SGFly",
            "line_desc": "Grouped Pays (15 Coins)",
            "dt_added": "2021-09-27 16:30:27",
            "dt_updated": "2021-09-27 16:30:27",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "6d9cc0aa-72e0-4706-b059-dbc339cc5102",
            "name": "Totem Towers",
            "key_name": "SGTotemTowers",
            "line_desc": "",
            "dt_added": "2021-07-13 18:00:57",
            "dt_updated": "2021-07-13 18:00:57",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "6f206d02-897e-4328-97f5-6ee071f4892d",
            "name": "Orbs of Atlantis",
            "key_name": "SGOrbsOfAtlantis",
            "line_desc": "",
            "dt_added": "2021-08-24 17:47:18",
            "dt_updated": "2021-08-24 17:47:18",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "6f820e21-e1f6-4022-bbb6-3ff6354fdd16",
            "name": "Sir Blingalot",
            "key_name": "SGSirBlingalot",
            "line_desc": "50 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "712ef7f7-7522-425e-856f-83b230098547",
            "name": "Shogun's Land",
            "key_name": "SGShogunsLand",
            "line_desc": "20 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "72fbe168-948c-4f8c-8965-adcc97d4a8a5",
            "name": "Double O Dollars",
            "key_name": "SGDoubleODollars",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "73bf27e8-dd8b-4375-9f96-df6963e1342e",
            "name": "5 Mariachis",
            "key_name": "SG5Mariachis",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:42",
            "dt_updated": "2020-04-09 07:17:42",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "73f27b83-3d37-41b5-a1c4-07db7a1a7e59",
            "name": "Wealth Inn",
            "key_name": "SGWealthInn",
            "line_desc": "8 Lines",
            "dt_added": "2020-07-28 04:18:31",
            "dt_updated": "2020-07-28 04:18:31",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "74408574-8e69-4154-9397-bb869a3e603b",
            "name": "Mr Bling",
            "key_name": "SGMrBling",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "74dcfb36-153b-4884-af9a-355a6b52039c",
            "name": "Presto!",
            "key_name": "SGPresto",
            "line_desc": "243 Ways (30 Coins)",
            "dt_added": "2020-04-09 07:17:42",
            "dt_updated": "2020-04-09 07:17:42",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "75316480-bf11-4429-a3fe-751b66566339",
            "name": "Magic Oak",
            "key_name": "SGMagicOak",
            "line_desc": "Grouped Pays (20 Coins)",
            "dt_added": "2020-04-09 07:17:42",
            "dt_updated": "2020-04-09 07:17:42",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "75fbb149-b70e-495e-82d6-9ea34a710fd2",
            "name": "Super Strike",
            "key_name": "SGSuperStrike",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "77165548-4067-4f74-b416-eae947699800",
            "name": "Hot Hot Fruit",
            "key_name": "SGHotHotFruit",
            "line_desc": "15 Lines",
            "dt_added": "2020-04-09 07:17:42",
            "dt_updated": "2020-04-09 07:17:42",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "7a214447-1cef-4a73-8abf-2577d8953ae0",
            "name": "King Tut's Tomb",
            "key_name": "SGKingTutsTomb",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "7fe89cf4-3eef-4ad6-baad-5576937ecfe6",
            "name": "Scopa",
            "key_name": "SGScopa",
            "line_desc": "",
            "dt_added": "2021-07-13 18:05:45",
            "dt_updated": "2021-07-13 18:05:45",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "81127d22-51c5-48ee-8172-b63196de6b3d",
            "name": "Jump!",
            "key_name": "SGJump",
            "line_desc": "192 - 1024 Ways (25 Coins)",
            "dt_added": "2020-04-09 07:17:42",
            "dt_updated": "2020-04-09 07:17:42",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "819bcec1-73a9-42a7-afb9-a4e3b79491e0",
            "name": "Dr Feelgood",
            "key_name": "SGDrFeelgood",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "84184547-4f8f-419b-9e07-23716b9d9eb2",
            "name": "Disco Funk",
            "key_name": "SGDiscoFunk",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "850eaebe-d50c-461c-b812-1a190cb5525e",
            "name": "Haunted House",
            "key_name": "SGHauntedHouse",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "8630b132-c908-457e-b28e-22f0f8a08b78",
            "name": "The Dead Escape",
            "key_name": "SGTheDeadEscape",
            "line_desc": "30 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "88790bfc-c31b-4aaf-b5b9-cb00ff398600",
            "name": "Blackbeard's Bounty",
            "key_name": "SGBlackbeardsBounty",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "8c5b38a7-c8e5-41ed-acc2-c878aacaf905",
            "name": "Dragon's Realm",
            "key_name": "SGDragonsRealm",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "93365059-166e-4e0e-aa18-f9f94df11ef7",
            "name": "Bikini Island",
            "key_name": "SGBikiniIsland",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "9663bb29-23c6-463e-99b7-e43899a9e655",
            "name": "Prost!",
            "key_name": "SGProst",
            "line_desc": "25 Lines",
            "dt_added": "2021-11-29 11:48:13",
            "dt_updated": "2021-11-29 11:48:13",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "978c9f7d-f630-4e29-b203-f09d958a5963",
            "name": "Pumpkin Patch",
            "key_name": "SGPumpkinPatch",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:42",
            "dt_updated": "2020-04-09 07:17:42",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "98a68b02-1e8c-47e9-809a-3ef510bc4b40",
            "name": "Pamper Me",
            "key_name": "SGPamperMe",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "9a2b7ca5-3fa5-4bfe-939d-a8671954102a",
            "name": "Sic Bo",
            "key_name": "SicBo",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Sic Bo"
        },
        {
            "brand_game_id": "a00ad0da-9318-419c-8dfd-cf4646d1e888",
            "name": "Buggy Bonus",
            "key_name": "SGBuggyBonus",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "a4c29817-0320-4275-8ef2-8a5ce8a39fee",
            "name": "Sparta",
            "key_name": "SGSparta",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "a4e1fb22-adcd-4bd8-a27c-1d6f4ef559ce",
            "name": "Jungle Rumble",
            "key_name": "SGJungleRumble",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "a8f1574f-9627-49c8-8f2d-fa5e643460c3",
            "name": "Jugglenaut",
            "key_name": "SGJugglenaut",
            "line_desc": "50 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "aa0e6114-0b61-4bde-b94a-218821746d5e",
            "name": "Knockout Football",
            "key_name": "SGKnockoutFootball",
            "line_desc": "Adjacent Ways (20 Coins)",
            "dt_added": "2020-04-09 07:17:42",
            "dt_updated": "2020-04-09 07:17:42",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "abc4959c-8ae6-4db7-97a5-9bea3ac5a3b0",
            "name": "Deuces Wild 1 Hand",
            "key_name": "DuecesWild1Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "ac0b3b25-fcee-4e8a-bcf6-221cc17c065c",
            "name": "Before Time Runs Out",
            "key_name": "SGBeforeTimeRunsOut",
            "line_desc": "",
            "dt_added": "2021-07-13 17:58:51",
            "dt_updated": "2021-07-13 17:58:51",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "aca12908-02ab-46f1-b560-2f5f23e0c40b",
            "name": "Jellyfish Flow Ultra",
            "key_name": "SGJellyfishFlowUltra",
            "line_desc": "27 Ways (15 Coins)",
            "dt_added": "2020-08-25 05:09:38",
            "dt_updated": "2020-08-25 05:09:38",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "ae359917-7a5f-40be-9076-33fcc5348eec",
            "name": "Egyptian Dreams",
            "key_name": "SGEgyptianDreams",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "af9d3584-7a97-4fdb-8d3b-44487d4f741e",
            "name": "Monster Mash Cash",
            "key_name": "SGMonsterMashCash",
            "line_desc": "50 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "b0ac7200-d073-41b9-8c32-71366475de25",
            "name": "The Big Deal",
            "key_name": "SGTheBigDeal",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "b1d59d4d-767c-470b-8bc8-0a9c4634c9e8",
            "name": "Aztlan's Gold",
            "key_name": "SGAzlandsGold",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "b5831271-f989-42a4-b5ff-33986c5adc05",
            "name": "Hey Sushi",
            "key_name": "SGHeySushi",
            "line_desc": "25 Lines",
            "dt_added": "2020-06-30 02:39:01",
            "dt_updated": "2020-06-30 02:39:01",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "b7252b32-95b0-4e2f-b1eb-baa23414d9f5",
            "name": "Fortune Dogs",
            "key_name": "SGFortuneDogs",
            "line_desc": "Horizontal Pays (18 Coins)",
            "dt_added": "2020-04-09 07:17:42",
            "dt_updated": "2020-04-09 07:17:42",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "ba2acd21-11de-4150-8843-6e26ae9cdad7",
            "name": "Flying High",
            "key_name": "SGFlyingHigh",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "be02649d-d411-4679-8a10-582cb7f3d255",
            "name": "Mummy Money",
            "key_name": "SGMummyMoney",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "bf9c5568-b704-4e3c-a499-f30a011b60a1",
            "name": "Gold Rush",
            "key_name": "SGGoldRush",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "c0265f8f-860f-482f-be1e-6d4ba8780b99",
            "name": "Queen of Queens II",
            "key_name": "SGQueenOfQueens1024",
            "line_desc": "1024 Ways (50  Coins)",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "c0e7df36-9671-4fd2-a54d-f53766aeb031",
            "name": "Treasure Tomb",
            "key_name": "SGTreasureTomb",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "c25ff182-7546-44f6-8c56-d1cf34d55d68",
            "name": "Arcane Elements",
            "key_name": "SGArcaneElements",
            "line_desc": "50 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "c3910e35-9b56-4b39-88ef-2b3fc80489a7",
            "name": "Gangsters",
            "key_name": "SGGangsters",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "c6e68085-73a9-4bac-9ae0-0a6d050c2d1c",
            "name": "London Hunter",
            "key_name": "SGLondonHunter",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:42",
            "dt_updated": "2020-04-09 07:17:42",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "c6f10697-59ef-49b4-acd3-2358442eca02",
            "name": "Bird of Thunder",
            "key_name": "SGBirdOfThunder",
            "line_desc": "30 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "c764e209-3b89-4884-88db-f359757e19b1",
            "name": "Roulette",
            "key_name": "EURoulette",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Roulette"
        },
        {
            "brand_game_id": "caeeb0ba-150f-4b05-a05b-79865a69e824",
            "name": "Techno Tumble",
            "key_name": "SGTechnoTumble",
            "line_desc": "Grouped Pays (25 Coins)",
            "dt_added": "2020-05-28 05:02:27",
            "dt_updated": "2020-05-28 05:02:27",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "cce6cdef-0d01-45d2-b067-aa0639930206",
            "name": "Arctic Wonders",
            "key_name": "SGArcticWonders",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "cdfbe967-dd7d-4ffe-b637-4b632f29f542",
            "name": "Knockout Football Rush",
            "key_name": "SGKnockoutFootballRush",
            "line_desc": "Horizontal & Vertical Pays (10 Coins)",
            "dt_added": "2020-04-09 07:17:42",
            "dt_updated": "2020-04-09 07:17:42",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "d060391b-198e-4e6a-aeed-1a8ce6f3c98c",
            "name": "Rolling Roger",
            "key_name": "SGRollingRoger",
            "line_desc": "Rolling Pays (25 Coins)",
            "dt_added": "2020-04-09 07:17:42",
            "dt_updated": "2020-04-09 07:17:42",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "d1bc41ef-c344-49f6-9e8d-10e01950ab1e",
            "name": "Barnstormer Bucks",
            "key_name": "SGBarnstormerBucks",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "d1d93b25-a1dd-4e11-ac4a-8c9a532bc0ca",
            "name": "War",
            "key_name": "TGWar",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "War"
        },
        {
            "brand_game_id": "d571f4c5-40eb-474b-93db-d9cef5d567c4",
            "name": "Lucky Durian",
            "key_name": "SGLuckyDurian",
            "line_desc": "15 Lines",
            "dt_added": "2022-01-24 14:21:57",
            "dt_updated": "2022-01-24 14:21:57",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "db4195a4-fcc7-4885-939d-71db55879585",
            "name": "Four Divine Beasts",
            "key_name": "SGFourDivineBeasts",
            "line_desc": "243 Ways (30 Coins)",
            "dt_added": "2020-04-09 07:17:42",
            "dt_updated": "2020-04-09 07:17:42",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "dc5e88bb-2880-490d-b420-172ca0b5c7fe",
            "name": "Double Exposure (3 Hand)",
            "key_name": "BlackJack3HDoubleExposure",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Blackjack"
        },
        {
            "brand_game_id": "ddde4e19-3843-4f27-9a56-ef8346793e0d",
            "name": "Bombs Away",
            "key_name": "SGBombsAway",
            "line_desc": "50 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "e11042f2-bd6f-431b-b932-8630697a49f4",
            "name": "Carnival Cash",
            "key_name": "SGCarnivalCash",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "e4158728-c355-45b4-9dae-bb449b1d0631",
            "name": "S.O.S!",
            "key_name": "SGSOS",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "e448c1dd-894c-4f88-ac3e-714cce20131f",
            "name": "Cash Reef",
            "key_name": "SGCashReef",
            "line_desc": "243 Ways (30 Coins)",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "e5facdd4-152e-4805-866a-30945b5b4931",
            "name": "Fenghuang",
            "key_name": "SGFenghuang",
            "line_desc": "28 Lines (20 Coins)",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "e6d7130b-27e0-42a5-89f9-f98a47a266fc",
            "name": "Naughty Santa",
            "key_name": "SGNaughtySanta",
            "line_desc": "432 Ways (30 Coins)",
            "dt_added": "2020-04-09 07:17:42",
            "dt_updated": "2020-04-09 07:17:42",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "e79f0f64-c114-43eb-8036-2aeb4909f7f7",
            "name": "Baccarat Zero Commission",
            "key_name": "Baccarat3HZC",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Baccarat"
        },
        {
            "brand_game_id": "e948df10-90d9-42d8-8963-eb0a5a50dd58",
            "name": "Frontier Fortunes",
            "key_name": "SGFrontierFortunes",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "ec2ef0b8-b955-4d84-ae36-ede82217ea75",
            "name": "Fire Rooster",
            "key_name": "SGFireRooster",
            "line_desc": "243 Ways (30 Coins)",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "ef62a0ac-a433-406f-971c-37fdc57a5172",
            "name": "Dragon Tiger",
            "key_name": "TGDragonTiger",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:42",
            "dt_updated": "2020-04-09 07:17:42",
            "game_type_name": "Dragon Tiger"
        },
        {
            "brand_game_id": "f0898f0c-74cc-4d7e-bdce-acd0896e053e",
            "name": "Mystic Fortune",
            "key_name": "SGMysticFortune",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "f15abefb-73ee-4905-9969-0625c9adbc18",
            "name": "Mystic Fortune Deluxe",
            "key_name": "SGMysticFortuneDeluxe",
            "line_desc": "28 Lines",
            "dt_added": "2021-08-31 02:19:10",
            "dt_updated": "2021-08-31 02:19:10",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "f5a28b44-1484-4d05-852f-0c02d8533c74",
            "name": "Zeus",
            "key_name": "SGZeus",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "f8118a6e-6ffc-46fc-83ac-16332ff3690d",
            "name": "Roman Empire",
            "key_name": "SGRomanEmpire",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "f82a5adb-f4c4-4df7-b2ed-b081a878b827",
            "name": "Lucky Lucky",
            "key_name": "SGLuckyLucky",
            "line_desc": "1 Line",
            "dt_added": "2020-04-09 07:17:42",
            "dt_updated": "2020-04-09 07:17:42",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "fbd0cc93-0d18-4308-a564-e54cf7306995",
            "name": "Christmas Gift Rush",
            "key_name": "SGChristmasGiftRush",
            "line_desc": "",
            "dt_added": "2021-07-13 18:00:57",
            "dt_updated": "2021-07-13 18:00:57",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "fc84b464-38a1-44d8-9941-cb9196a5b235",
            "name": "Zeus 2",
            "key_name": "SGZeus2",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "ff203c98-ea18-4e35-a7fc-f053ee2ff5da",
            "name": "Pool Shark",
            "key_name": "SGPoolShark",
            "line_desc": "25 Lines",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Slots"
        },
        {
            "brand_game_id": "0128316f-1b1a-437c-88cb-7b54655acfe7",
            "name": "Tens or Better 50 Hand",
            "key_name": "TensorBetter50Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "023afa23-cbb3-4405-a616-d1f88bc279e3",
            "name": "Jacks or Better 50 Hand",
            "key_name": "JacksorBetter50Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "0865d43f-874f-4217-9e7a-fd07c3f91ef2",
            "name": "Bonus Deuces Wild 10 Hand",
            "key_name": "BonusDuecesWild10Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "10b1bb06-8fe3-4746-8d5c-4b965061fc97",
            "name": "Three Card Poker Deluxe",
            "key_name": "TGThreeCardPokerDeluxe",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Casino Poker"
        },
        {
            "brand_game_id": "1181cc8a-ee90-400b-adfb-aec6ab8b6891",
            "name": "Double Bonus Poker 10 Hand",
            "key_name": "DoubleBonusPoker10Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "f90f0476-ee2a-4474-98c2-98ee93d002a0",
            "name": "Double Double Bonus Poker 50 Hand",
            "key_name": "DoubleDoubleBonusPoker50Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "fac9fb1c-ad33-4f67-b2cc-93f3753e39ac",
            "name": "Joker Poker 1 Hand",
            "key_name": "JokerPoker1Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "f60f528d-65e9-43ab-a91a-a7ef975e8015",
            "name": "Double Double Bonus Poker 10 Hand",
            "key_name": "DoubleDoubleBonusPoker10Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "f6a74194-7c87-426e-b0f4-c261bd137e8e",
            "name": "Caribbean Stud",
            "key_name": "CaribbeanStud",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Casino Poker"
        },
        {
            "brand_game_id": "e9d3c0c8-8e3f-4c0c-bb78-7f6701528393",
            "name": "Joker Poker 100 Hand",
            "key_name": "JokerPoker100Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "0e29de6f-564a-4e2f-94a8-fb120bca0dc1",
            "name": "Aces and Eights 10 Hand",
            "key_name": "AcesandEights10Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "0fe3fc0c-14c8-4172-b495-cc34bcaccb22",
            "name": "Aces and Eights 5 Hand",
            "key_name": "AcesandEights5Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "f35c7169-762d-49bd-9c15-fd5e85fedb2e",
            "name": "Jacks or Better 1 Hand",
            "key_name": "JacksorBetter1Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "12b3a728-ab7e-4662-96dc-59f211a36898",
            "name": "Jacks or Better 10 Hand",
            "key_name": "JacksorBetter10Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "15a8afda-c648-49fe-814f-2b00b2dbd444",
            "name": "Bonus Deuces Wild 5 Hand",
            "key_name": "BonusDuecesWild5Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "15c6448a-4f5b-4eb9-a337-0b69dea5b5fb",
            "name": "Tens or Better 5 Hand",
            "key_name": "TensorBetter5Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "1680192d-8368-45f0-89c5-91f58f77faa2",
            "name": "Double Double Bonus Poker 5 Hand",
            "key_name": "DoubleDoubleBonusPoker5Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "26dea2f1-3c5c-43d0-82ad-1067b75d93d8",
            "name": "Joker Poker 50 Hand",
            "key_name": "JokerPoker50Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "25534e09-18e0-411e-b4ff-491d7c2e87e1",
            "name": "Blackjack (3 Hand)",
            "key_name": "BlackJack3H",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Blackjack"
        },
        {
            "brand_game_id": "2be4f9e4-a8fc-4433-ac46-4274d48f7f14",
            "name": "Tens or Better 100 Hand",
            "key_name": "TensorBetter100Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "2d1819b9-6d20-4431-821d-070f2d269116",
            "name": "Double Double Bonus Poker 1 Hand",
            "key_name": "DoubleDoubleBonusPoker1Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "2ac9fc41-51b6-4084-8fb2-07313b095144",
            "name": "Caribbean Holdem",
            "key_name": "CaribbeanHoldem",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Casino Poker"
        },
        {
            "brand_game_id": "44cc51a1-dd35-4354-b4a0-ee134fe4082a",
            "name": "Double Bonus Poker 1 Hand",
            "key_name": "DoubleBonusPoker1Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "44e69469-e255-46a3-bfb5-e74bf958d07a",
            "name": "Deuces Wild 5 Hand",
            "key_name": "DuecesWild5Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "3865ed37-c625-4c3c-8e3c-26242bb26145",
            "name": "Bonus Poker 10 Hand",
            "key_name": "BonusPoker10Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "32e8fdca-d617-4ecc-a205-0e194530fb07",
            "name": "Bonus Poker 100 Hand",
            "key_name": "BonusPoker100Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "5e35ea67-e0a5-44eb-b0d6-89589cb93370",
            "name": "All American Poker 10 Hand",
            "key_name": "AllAmericanPoker10Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "605f4b6b-d7a0-445b-99e3-1458596ade83",
            "name": "All American Poker 1 Hand",
            "key_name": "AllAmericanPoker1Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "60d5ea9f-1d56-419a-a967-5e4645b567b6",
            "name": "Deuces Wild 50 Hand",
            "key_name": "DuecesWild50Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "59f2808d-f95e-4a37-9ed0-2cbc47733cee",
            "name": "Bonus Deuces Wild 100 Hand",
            "key_name": "BonusDuecesWild100Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "5c6f0167-1ba5-4df4-9cfa-8e931bf397b2",
            "name": "Bonus Poker 1 Hand",
            "key_name": "BonusPoker1Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "581eec96-697d-4d16-9df1-142074772390",
            "name": "All American Poker 5 Hand",
            "key_name": "AllAmericanPoker5Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "5143b89f-d5fc-42a7-9d01-10f2177f41b7",
            "name": "Tens or Better 10 Hand",
            "key_name": "TensorBetter10Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "4f48b9ce-0303-4762-a76f-00266fd30ec8",
            "name": "Deuces Wild 100 Hand",
            "key_name": "DuecesWild100Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "a727422d-c0b2-4bbe-842b-8f219bcc2dc8",
            "name": "Aces and Eights 100 Hand",
            "key_name": "AcesandEights100Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "993fc5f8-b0af-4181-b767-79c01aca6a7b",
            "name": "Poker Gamble - Beat the Dealer",
            "key_name": "GambleBeatDealer",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Gamble"
        },
        {
            "brand_game_id": "99488cba-1ae6-4397-838e-82acfc82334e",
            "name": "Double Bonus Poker 100 Hand",
            "key_name": "DoubleBonusPoker100Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "9364f63b-8cac-4db0-8341-a8fbdd10091b",
            "name": "Deuces Wild 10 Hand",
            "key_name": "DuecesWild10Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "9057ddc6-6286-44be-82d9-dbfec88d8c9e",
            "name": "Bonus Deuces Wild 1 Hand",
            "key_name": "BonusDuecesWild1Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "914ceb8e-5af9-471a-9ff6-4504e8c6bc55",
            "name": "Joker Poker 5 Hand",
            "key_name": "JokerPoker5Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "85f19ca2-de3e-400e-a8d2-fe8d8ec16d8c",
            "name": "Bonus Poker 5 Hand",
            "key_name": "BonusPoker5Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "83c3943e-9615-4953-bb22-ee9881e1f062",
            "name": "American Blackjack",
            "key_name": "TGBlackjackAmerican",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:42",
            "dt_updated": "2020-04-09 07:17:42",
            "game_type_name": "Blackjack"
        },
        {
            "brand_game_id": "75a539a3-d0c8-4538-9e05-b0a5fb611d2d",
            "name": "Bonus Poker 50 Hand",
            "key_name": "BonusPoker50Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "6e35435a-7257-4759-9438-c1805204cf24",
            "name": "Aces and Eights 1 Hand",
            "key_name": "AcesandEights1Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "6d28075d-ed53-40bd-aee7-7a9c6a118770",
            "name": "Aces and Eights 50 Hand",
            "key_name": "AcesandEights50Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "6c41cf64-93a8-4edd-95a4-78e7ec557458",
            "name": "Joker Poker 10 Hand",
            "key_name": "JokerPoker10Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "6a24904f-3e16-4480-9175-0ff99d7c363d",
            "name": "Three Card Poker",
            "key_name": "TGThreeCardPoker",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Casino Poker"
        },
        {
            "brand_game_id": "e8d25fc5-c06d-4bb8-9f86-593db61060ad",
            "name": "Double Bonus Poker 5 Hand",
            "key_name": "DoubleBonusPoker5Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "e646c273-4b88-43ee-8317-7b8cab81590f",
            "name": "Double Bonus Poker 50 Hand",
            "key_name": "DoubleBonusPoker50Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "e33858e5-9aa8-460d-827e-92e0952cd0cc",
            "name": "Bonus Deuces Wild 50 Hand",
            "key_name": "BonusDuecesWild50Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "d9256091-fddf-4f38-9c65-8e26b6c54841",
            "name": "All American Poker 50 Hand",
            "key_name": "AllAmericanPoker50Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "c81b6eb2-9081-4c5b-9bd7-428e96c1742e",
            "name": "Double Double Bonus Poker 100 Hand",
            "key_name": "DoubleDoubleBonusPoker100Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "c2349e45-1d48-446c-85c8-eddfafc34f1f",
            "name": "Tens or Better 1 Hand",
            "key_name": "TensorBetter1Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "b530db57-02b8-44aa-8384-6bfaf73dbcf1",
            "name": "Jacks or Better 100 Hand",
            "key_name": "JacksorBetter100Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "af17a7c4-3d7c-459b-8462-d07517dd824b",
            "name": "All American Poker 100 Hand",
            "key_name": "AllAmericanPoker100Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
        {
            "brand_game_id": "ac1a1165-599d-4a7e-88f7-1d0528bbc5a1",
            "name": "Jacks or Better 5 Hand",
            "key_name": "JacksorBetter5Hand",
            "line_desc": "",
            "dt_added": "2020-04-09 07:17:41",
            "dt_updated": "2020-04-09 07:17:41",
            "game_type_name": "Video Poker"
        },
    ]
};
