import React from 'react';

// import onlineIcon from '/icons/onlineIcon.png';
// import closeIcon from '/icons/closeIcon.png';

import './InfoBar.css';

const InfoBar = ({ room, total }) => (
  <div className="infoBar">
    <div className="leftInnerContainer">
      <img className="onlineIcon" src="/icons/onlineIcon.png" alt="online icon" />
      <h3>{room} {(total)? "("+total+")" : ""}</h3>
    </div>
    <div className="rightInnerContainer">
      <a href="/"><img src="/icons/closeIcon.png" alt="close icon" /></a>
    </div>
  </div>
);

export default InfoBar;