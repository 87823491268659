/* eslint no-dupe-keys: 0 */
import React from 'react';
import { ListView } from 'antd-mobile';

// import { FacebookProvider, CommentsCount } from 'react-facebook';

import {
  Link
} from 'react-router-dom';

import { Grid } from 'antd-mobile';
import AppHeader from './../AppHeader';

import axios from 'axios';
var moment = require('moment');

let NUM_ROWS = 20;
let LIMIT_ROWS = 20;
let TOTAL_INDEX = 0;
//let MAX_LENGTH = 90;
let MAX_LENGTH = 90;

function genData(pIndex = 0) {
  const dataBlob = {};
  for (let i = 0; i < NUM_ROWS; i++) {
    const ii = (pIndex * NUM_ROWS) + i;
    dataBlob[`${ii}`] = `row - ${ii}`;
  }
  return dataBlob;
}

class StatementDetailPage extends React.Component {
  constructor(props) {
    super(props);

    window.BPdata.footerMenu = "home";

    var dataSource = new ListView.DataSource({
      rowHasChanged: (r1, r2) => r1 !== r2
    });

    this.data = [];
    this.state = {
      daily : '',
      post_id : 0,
      betlist : 'betlist',
      bet_list    : [],
      result_list : [],
      dataSource: dataSource.cloneWithRows(this.data),
      isLoading: true,
      advertise: []

    };

    this.pageIndex = 1;
    TOTAL_INDEX = 0;
    NUM_ROWS = 20;
    if(this.props.perPage) {
      NUM_ROWS = this.props.perPage;
      TOTAL_INDEX = this.props.pageIndexTotal;
      //console.log(TOTAL_INDEX)
      this.state.isLoading = true;

    }

    if(props.match.params.id) {
      this.state.post_id = props.match.params.id
    }


    this.changeSubMenu      = this.changeSubMenu.bind(this);

  }

  componentDidMount() {

    // if(localStorage.getItem('home_advertise')) {

    //   if(JSON.parse(localStorage.getItem("home_advertise")) != null) {
    //     let home_advertise = JSON.parse(localStorage.getItem("home_advertise"));
    //     this.setState({advertise: home_advertise})
    //   }
    // }

    // if(JSON.parse(localStorage.getItem("videoSlides")) != null) {
    //   let video_slides = JSON.parse(localStorage.getItem("videoSlides"));
    //   this.setState({
    //     dataSource: this.state.dataSource.cloneWithRows(video_slides.reverse()),
    //     isLoading: true,
    //   });
    // }

    // if(localStorage.getItem('homeData')) {
    //   if(JSON.parse(localStorage.getItem("homeData")) != null) {
    //     let home_data = JSON.parse(localStorage.getItem("homeData"));
    //     this.setState({homeData: home_data})
    //     this.setState({
    //       dataSource: this.state.dataSource.cloneWithRows(home_data.dataSource),
    //       isLoading: false,
    //       advertise: home_data.advertise
    //     });
    //   }
    // }
    //TOTAL_INDEX = 20;
    // you can scroll to the specified position
    setTimeout(() => this.lv.scrollTo(0, 120), 800);

    // simulate initial Ajax
    setTimeout(() => {
      this.fetchData();
    }, 600);
  }

  componentWillUnmount() {
    clearInterval(this.state)
  }


  changeSubMenu(step) {
      // if(step === 'betlist') {
        this.setState({ betlist : step});

        if(step === 'betlist') {
          this.data = this.bet_list;
        } else {
          this.data =   this.result_list;
        }

        this.fetchData();
          // if(this.state.betlist) {
          //     this.setState({ betlist : step});
          // } else {
          //     this.setState({ betlist : step});
          // }
      // }

  }

  fetchData() {

        let dataurl = window.BPdata.apiUrl+'/service/cus-statement/'+this.state.post_id+'?limit='+NUM_ROWS+'&game_type=1';
        // let data = this.state.data;
        axios({
            method: 'get',
            url: dataurl,
            // data: data,
            headers: {
              "Content-Type": "application/json; charset=utf-8",
              "X-Trident-Token"  : window.BPdata.token,
              "BP-Agent"         : window.BPdata.rand_compound,
              "BP-Time"          : localStorage.getItem(window.BPdata.time)
              // "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then(resp => {


          // console.log(this.data.concat(resp.data));

          if(resp.data.result_list.data) {

              this.daily = resp.data.daily;

          }


          this.bet_list = resp.data.result_list.data;

          this.data = resp.data.result_list.data;

          // } else {

          //   this.data = resp.data.result_list;

          // }


          // console.log("-----------------");

            this.setState({
              dataSource: this.state.dataSource.cloneWithRows(this.data.reverse()),
              isLoading: false
            });

            NUM_ROWS = Number(NUM_ROWS) + Number(LIMIT_ROWS);

            //console.log(this.state.advertise)

        })
        .catch(function(error) {
          //console.log(error);
        });
    // fetch(REQUEST_URL)
    //   .then((response) => response.json())
    //   .then((responseData) => {
    //     this.setState({
    //       dataSource: this.state.dataSource.cloneWithRows(responseData[0].data),
    //     });
    //   })
    //   .done();
  }


  onEndReached = (event) => {
    // load new data
    // hasMore: from backend data, indicates whether it is the last page, here is false
    if (this.state.isLoading && !this.state.hasMore) {

      return;
    }
    // console.log('reach end', event);
    //this.setState({ isLoading: true });
    if(TOTAL_INDEX !== 0) {
      if(this.pageIndex+1 >= TOTAL_INDEX) {
        this.setState({
          isLoading: false,
        });
      } else {
        this.setState({ isLoading: true });
      }
    } else {
      this.setState({ isLoading: false });
    }

    setTimeout(() => {
      //console.log(this.pageIndex)
      if(TOTAL_INDEX !== 0) {

        if(this.pageIndex+1 >= TOTAL_INDEX) {
          return ;
        }

        //this.data = { ...this.data, ...genData(++this.pageIndex) };

        this.fetchData();
        // this.setState({
        //   // dataSource: this.state.dataSource.cloneWithRows(this.rData),
        //   isLoading: false,
        // });
      } else {
        this.data = { ...this.data, ...genData(++this.pageIndex) };
        this.fetchData();
        // this.setState({
        //   dataSource: this.state.dataSource.cloneWithRows(this.rData),
        //   isLoading: false,
        // });
      }

    }, 1000);
  }

  render() {
    const separator = (sectionID, rowID) => (
      <div
        key={`${sectionID}-${rowID}`}
        style={{
          backgroundColor: '#F5F5F9',
          height: 8,
          borderTop: '1px solid #ECECED',
          borderBottom: '1px solid #ECECED',
        }}
      />
    );
    let index = this.data.length - 1;
    let ui = "";
    let adcount = 0;
    const row = (rowData, sectionID, rowID) => {
      if (index < 0) {
        index = this.data.length - 1;
      }
      const obj = this.data[index--];

      if (this.state.betlist == "betlist") {

          ui =  <div className="row grid-100">
                      <div className="grid-90">
                        <div className="grid-100 left pl-20">
                            <br />
                            <Link to={"/more/statement/"+obj.id}>{moment.utc(obj.created_at).local().format('DD-MM-YYYY hh:mm A') }</Link>
                            <br /><br />
                        </div>
                      </div>

                      <div className="adm-card row  bg-noise">
                          <div className="adm-card-body grid-90">
                                  <div className="grid-30 left pl-20 color-theme-gold">
                                    <b >From</b> : <br /> {obj.chamount }
                                      <br />
                                      <b >To</b> : <br /> {obj.tochamount }

                                  </div>
                                  <div className="grid-30 left color-theme-gold">

                                    <b >Main Wallet</b> : <br /> {obj.main_wallet }
                                    <br />
                                    <b >Game Wallet</b> : <br /> {obj.game_wallet }


                                  </div>
                                  <div className="grid-30 left color-theme-gold">
                                    <b >Commission Wallet</b> :  <br /> {obj.commission_wallet }
                                  </div>

                          </div>
                          <div className="adm-card-body row">

                              <div className="grid-90  pl-20 pt-4 pb-4">
                                <hr style={{ "borderBottom": "1px solid #FFFFFF" , "opacity": "0.25" }} />
                              </div>
                          </div>

                          <div className="adm-card-body grid-90">
                                  <div className="grid-30 left pl-20 color-theme-gold">
                                       <b >Service Type</b> : <br />  {obj.mainservicetype } {obj.subservicetype }

                                  </div>
                                  <div className="grid-30 left color-theme-gold">
                                      <b >Amount</b> :  <br /> {obj.amount }

                                  </div>
                                  <div className="grid-30 left color-theme-gold">

                                      <b >Total Balance</b> : <br /> {obj.total_wallet }

                                  </div>

                          </div>
                      </div>
              </div>;


      }


      return (
          ui
      );
    };
    return (
      <div className="page">
        <AppHeader backLink="/more/statement" titleType="3" title={window.lang.more.statement} isLoading="false" />
        <div className="page-content">
            <div className="main-content">

                <div className="row">
                  <div className={ (this.state.betlist == "betlist") ? 'grid-50 left text-center tab-holder active' : 'grid-50 left text-center tab-holder'}  onClick={this.changeSubMenu.bind(this,"betlist")}  >
                    {window.lang.more.statement}
                  </div>



                </div>
                <div className="row text-left">


                    <ListView
                      ref={el => this.lv = el}
                      dataSource={this.state.dataSource}
                      renderHeader={() => ""}
                      renderFooter={() => (<div style={{ padding: this.state.isLoading ? 30 : 0 , textAlign: 'center', display: this.state.isLoading ? 'block' : 'none' }}>
                        {this.state.isLoading ? <img src="/img/loading.gif" alt="loading" height="50px"  /> : ''}
                      </div>)}
                      renderRow={row}
                      // renderSeparator={separator}
                      className="am-list"
                      pageSize={10}
                      useBodyScroll
                      // onScroll={() => { console.log('scroll'); }}
                      scrollRenderAheadDistance={500}
                      onEndReached={this.onEndReached}
                      onEndReachedThreshold={10}
                    />
                </div>
          </div>
        </div>

        <footer  className="App-footer">

          <div className="App-footer-inner">
            {/* {window.BPdata.footerMenu} */}
            <Grid data={window.lang.footer}
                columnNum={5}
                renderItem={dataItem => (

                  <Link to={dataItem.url} className="cont-wrap-link hvr-pulse-shrink" style={{width: '100%'}}>
                        { (window.BPdata.footerMenu === dataItem.check )  ? (dataItem.text == "My Profile") ?  <span className="color-white"><img src={dataItem.icon}  height="25px"/><br />{(this.checklogin()) ? dataItem.text : "Login / Register" }</span> : <span className="text-white"><img src={dataItem.icon_off}  height="25px"/><br /><span >{dataItem.text}</span> </span> : <span className="text-white"><img src={dataItem.icon}  height="25px"/><br />{dataItem.text} </span> }

                  </Link>
                )}
              />

          </div>
        </footer>

      </div>
    );
  }
}

export default StatementDetailPage;
