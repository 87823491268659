import React, { Component } from "react";
import axios from 'axios';
import ImageUploading from "react-images-uploading";
require('./../../BPdata.js')

const maxNumber = 10;
const maxMbFileSize = 8;

export default class CommentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: "",

      comment: {
        type_id : props.type_id,
        salt: localStorage.getItem(window.BPdata.time),
        message: "",
        featured_image : ""
      },
      featured_image : ""
    };

    // bind context to methods
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    //console.log(props)
    if(this.props.post_id) {
      this.state.post_id = this.props.post_id
      this.state.type_id = this.props.type_id
    }
    // this.setState({ comment : {
    //     salt : localStorage.getItem(window.BPdata.time)
    //   } 
    // });
  }

  componentDidMount() {
    console.log("form -------" + this.props.post_id)
  }

  onChange = (imageList) => {
      // data for submit
      this.fileUpload(imageList);
  };

  fileUpload = (imageList) => {
      let fd = imageList;
      
      // fd.push({id: this.state.post_id});

      axios.post(window.BPdata.apiUrl+'/user/featureimage/'+this.state.post_id+'/upload', fd).then(res=>
          {
            if(res.data) {
              if(res.data.length>0) {
                  //this.setState({featured_image : row.url})
                  for (let index = 0; index < res.data.length; index++) {
                      let row = res.data[index];
                      this.setState({featured_image : row.shorturl})

                      this.setState({
                        comment: {
                          type_id         : this.state.comment.type_id,
                          salt            : localStorage.getItem(window.BPdata.time),
                          featured_image  : this.state.featured_image,
                          message         : this.state.comment.message
                        }
                      })
                      // let img = '!['+row.name+']('+row.url+' "'+row.name+'")';
                      // let data = this.state.mdeState +' '+ img;
                      // this.setState({mdeState: data });
                  }
              }
            }
              
          }
      );         
  }  
  /**
   * Handle form input field changes & update the state
   */
  handleFieldChange = event => {
    const { value, name } = event.target;

    this.setState({
      ...this.state,
      comment: {
        ...this.state.comment,
        [name]: value
      }
    });
  };

  /**
   * Form submit handler
   */
  onSubmit(e) {
    // prevent default form submission
    e.preventDefault();

    if (!this.isFormValid()) {
      this.setState({ error: "All fields are required." });
      return;
    }

    // loading status and clear error
    this.setState({ error: "", loading: true });

    
    var self = this;

    let { comment } = this.state;

    const dataurl = window.BPdata.apiUrlCMS+'/player/comment/'+this.state.post_id+'/post';
    
    axios({
        method: 'post',
        url: dataurl,
        data: JSON.stringify(comment),
        headers: { 'content-type': 'application/json;charset=UTF-8', 
          "X-Trident-Token" : window.BPdata.token,
          "BP-Agent"         : window.BPdata.rand_compound
        },
    })
    .then(function (response) {

        this.setState({
            loading: false,
            comment: {
              type_id : this.props.type_id,
              salt: localStorage.getItem(window.BPdata.time),
              message: "",
              featured_image  : ""
            },
            featured_image : ""
          });

        this.props.addComment(response);

    })
    .catch(function (error) {
        this.setState({
          error: "Something went wrong while submitting form.",
          loading: false
        });
    });


    // // persist the comments on server
    // let { comment } = this.state;
    // fetch( window.BPdata.apiUrl+'/user/comment/'+this.state.post_id+'/post', {
    //   method: "post",
    //   body: JSON.stringify(comment)
    // })
    //   .then(res => res.json())
    //   .then(res => {
    //     if (res.error) {
    //       this.setState({ loading: false, error: res.error });
    //     } else {
    //       // clear the message box
    //       this.setState({
    //         loading: false,
    //         comment: {
    //           type_id : this.props.type_id,
    //           salt: localStorage.getItem(window.BPdata.time),
    //           message: "",
    //           featured_image  : ""
    //         },
    //         featured_image : ""
    //       });

    //       this.props.addComment(res);

    //     }
    //   })
    //   .catch(err => {
    //     this.setState({
    //       error: "Something went wrong while submitting form.",
    //       loading: false
    //     });
    //   });
  }

  /**
   * Simple validation
   */
  isFormValid() {
    return this.state.comment.name !== "" && this.state.comment.message !== "";
  }

  renderError() {
    return this.state.error ? (
      <div className="alert alert-danger">{this.state.error}</div>
    ) : null;
  }

  render() {
    return (
      <React.Fragment>
        <form method="post" >
          {/* <div className="form-group">
            <input
              onChange={this.handleFieldChange}
              value={this.state.comment.name}
              className="form-control"
              placeholder="😎 Your Name"
              name="name"
              type="text"
            />
          </div> */}

          <div className="form-group">
            <textarea
              onChange={this.handleFieldChange}
              value={this.state.comment.message}
              className="comment"
              placeholder={"🙎 "+ window.lang.messages.your_comment}
              name="message"
              rows="4"
            />
            <input type="hidden" name="featured_image" value={this.state.comment.featured_image} onChange={this.handleFieldChange}/>
          </div>

          {this.renderError()}
          </form>
          <div className="form-group">
            
            <br/>
            <button disabled={this.state.loading} className="btn btn-success" onClick={this.onSubmit}>
              {window.lang.form.submit} &#10148;
            </button>
            
            {(this.state.featured_image !== "") ? <div><br/><br/> <img src={window.BPdata.apiAssetUrl+"/images/user/"+this.state.featured_image} alt=""/> </div> : "" }
            <br/><br/>
          </div>
        
        
      </React.Fragment>
    );
  }
}
