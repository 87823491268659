import React, { useEffect, useState } from 'react';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import 'firebase/messaging';


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional


// Initialize Firebase


const NotiTestPage = () => {
  const [token, setToken] = useState(null);
  const [permission, setPermission] = useState(null);
  const [message, setMessage] = useState(null);

  useEffect(() => {
    const firebaseConfig = {
      apiKey: "AIzaSyDnIAPFA01b1FS_KOrz1g4LgxveeCzMtlQ",
      authDomain: "AThaPyar-96fff.firebaseapp.com",
      projectId: "AThaPyar-96fff",
      storageBucket: "AThaPyar-96fff.appspot.com",
      messagingSenderId: "918243368430",
      appId: "1:918243368430:web:0ca0f9a3b13b1d48d402ff",
      measurementId: "G-XQ1SQ4W65H"
    };

    // const app = initializeApp(firebaseConfig);
    // const analytics = getAnalytics(app);

    const firebase = initializeApp(firebaseConfig);
    const messaging = firebase.messaging();

    messaging.requestPermission()
      .then(() => {
        console.log('Notification permission granted.');
        setPermission(true);
        return messaging.getToken();
      })
      .then(firebaseToken => {
        console.log(firebaseToken);
        setToken(firebaseToken);
      })
      .catch(error => {
        console.error(error);
        setPermission(false);
      });

    messaging.onMessage((payload) => {
      console.log('Message received. ', payload);
      setMessage(payload.notification.title);
    });
  }, []);

  return (
    <div>
      <p>Token: {token}</p>
      <p>Permission: {permission ? 'Granted' : 'Denied'}</p>
      <p>Message: {message}</p>
    </div>
  );
};

export default NotiTestPage;
