import React from 'react';
import axios from 'axios';

import {
    Link
  } from 'react-router-dom';
import { ListView,Grid,Modal } from 'antd-mobile';

import AppHeader from './../AppHeader';

import swal from 'sweetalert';

import StatusAlert, { StatusAlertService } from 'react-status-alert'
import 'react-status-alert/dist/status-alert.css'

const alert = Modal.alert;
var moment = require('moment');

class WalletTopUpStore extends React.Component {
    constructor(props) {
        super(props)
        window.BPdata.footerMenu = "wallet";
        this.state = {
                        viber_ph_1 : "959755788827",
                        backLink: "/more/wallet-store/",
                        bullet : 0,
                        message: "",
                        wallet : [
                            {
                                wallet_id: 1,
                                credit : 0
                            },
                            {
                                wallet_id: 2,
                                credit : 0
                            }
                        ],
                        data : {},
                        step: "list",
                        league : false,
                        advertise : {advertise_image:""},
                        isLoading: false,
                        check: 0,
                        payment: [
                              { id : 1, shortcode : "K Pay" , image : "/img/banks/active/kbz.png", non_image : "/img/banks/in-active/kbz.png" },
                              { id : 2, shortcode : "Wave Moneny" , image : "/img/banks/active/wave.png", non_image : "/img/banks/in-active/wave.png"  },
                              { id : 3, shortcode : "Aya Pay" , image : "/img/banks/active/aya_pay.png", non_image : "/img/banks/in-active/aya_pay.png" },
                              { id : 4, shortcode : "CB Pay" , image : "/img/banks/active/cb_pay.png", non_image : "/img/banks/in-active/cb_pay.png"  }
                        ],
                        form :   {

                                "description":"",
                                "salt":localStorage.getItem(window.BPdata.time),
                                "to_user_id":1,
                                "fromgame_id":1,
                                "togame_id":23,
                                "transfer_type":1,
                                "provider_id": 1,
                                "customerbank":{
                                    "bank_acc_name":"",
                                    "bank_type":1,
                                    "bank_num":"",
                                    "id": 0
                                },
                                "dagonbank": {
                                    "id":0,
                                    "holdername":"",
                                    "bank_type":0,
                                    "cardnum":""
                                },
                                transaction_date: moment().format("YYYY-MM-DD hh:mm A"),
                                "amount": "",
                                "bank_transaction_id":""
                        },
                         permit : {
                            transfer_types : [
                                { id : 0, name : "-"},
                                { id : 1, name : "Deposit"},
                                { id : 2, name : "Withdrawal"},
                                { id : 3, name : "Transfer"}
                            ],
                            bank_types : [
                                { id : 1, shortcode : "K Pay" , image : "/img/banks/active/kbz.png", non_image : "/img/banks/in-active/kbz.png" },
                                { id : 2, shortcode : "Wave Moneny" , image : "/img/banks/active/wave.png", non_image : "/img/banks/in-active/wave.png"  },
                                { id : 3, shortcode : "Aya Pay" , image : "/img/banks/active/aya_pay.png", non_image : "/img/banks/in-active/aya_pay.png" },
                                { id : 4, shortcode : "CB Pay" , image : "/img/banks/active/cb_pay.png", non_image : "/img/banks/in-active/cb_pay.png"  }
                            ],
                            bank_accounts : [
                                { id : 1, bank_type: 1, shortcode : "AYA", holdername : "", cardnum : "" }
                            ],
                            specfic_bank_accounts : [
                                { id : 1, bank_type: 1, shortcode : "AYA", holdername : "", cardnum : "" }
                            ],
                            customer_banks : [
                                 { id : 1, bank_type: 1,bank_acc_name : "", bank_num : "" }
                            ],
                            specfic_cus_bank_accounts : [
                                  { id : 1, bank_type: 1,bank_acc_name : "", bank_num : "" }
                            ],
                            from_customer : {
                                id : 0,
                                first_name : "",
                                date_of_birth : ""
                            },
                            to_customer : {
                                id : 0,
                                first_name : "",
                                date_of_birth : ""
                            },
                            last10trans : [{
                                id  : 44,
                                player_id :  802,
                                to_player_id  :  802,
                                from_total : 0,
                                percentage : 0,
                                bonus_type : 0,
                                amount : 330,
                                transfer_type : 1,
                                description : "",
                                staff_id   : 6,
                                cusbank_bank_acc_name :  "sdfd",
                                cusbank_bank_num  :  "32232323",
                                cusbank_bank_type  : 1,
                                dagonbank_id  :  1,
                                fromgame_id : "",
                                togame_id   : "",
                                transaction_date: moment().format("YYYY-MM-DD hh:mm A"),
                                approve_status : 0,
                                provider_id : 1,
                                bank_transaction_id : "2333232"
                            } ],
                            approve_statuses : [
                                { id : 0, name : "New"},
                                { id : 1, name : "Pending"},
                                { id : 2, name : "Confirm"},
                                { id : 3, name : "Cancel"},
                            ],
                        },
                        hints : {
                            array : [],
                            string : [],
                        },
                        bankaccount : { },
                        alertId: ''
                    };
        this.setAmount      = this.setAmount.bind(this);

        this.changeBankType = this.changeBankType.bind(this);
        this.changeSubMenu      = this.changeSubMenu.bind(this);

        // this.clickCopy          = this.clickCopy.bind(this);
        // this.copyToClipboard    = this.copyToClipboard.bind(this);

        this.showSuccessAlert = this.showSuccessAlert.bind(this);
        this.removeAlert = this.removeAlert.bind(this);
        this.sendTransactionApi      = this.sendTransactionApi.bind(this);
        this.showMessage = this.showMessage.bind(this);
        this.showErrorAlert     = this.showErrorAlert.bind(this);

        this.onBankCopyChange = this.onBankCopyChange.bind(this);
        this.apkCheck   = this.apkCheck.bind(this);

    }

    apkCheck() {
        var isMobile = /AThaPyar/i.test(navigator.userAgent);
        // console.log(isMobile+ "--");
        return isMobile;
    }

    onCompanyBankTypeChange(e) {
        // console.log("ok")
        let bank_accounts = [];

        // let dagonbank_id = this.form.dagonbank.id;
        let dagonbank_id = e.target.value;
        let selectcompanybank = {};

        for(let i = 0; i< this.state.permit.specfic_bank_accounts.length; i++){

            // console.log(dagonbank_id + this.state.permit.specfic_bank_accounts[i].id)
            if(dagonbank_id == this.state.permit.specfic_bank_accounts[i].id) {

                selectcompanybank.id = this.state.permit.specfic_bank_accounts[i].id;
                selectcompanybank.holdername = this.state.permit.specfic_bank_accounts[i].holdername;
                selectcompanybank.bank_type = this.state.permit.specfic_bank_accounts[i].bank_type;
                selectcompanybank.cardnum = this.state.permit.specfic_bank_accounts[i].cardnum;

                // console.log(this.form.dagonbank.id + "------")

            }

        }

        this.setState(prevState => ({
            form: {
                ...prevState.form,
                dagonbank: selectcompanybank,
            }
        }))


        e.preventDefault();

    }

    onCusBankChange(e) {
        // console.log("ok")

        // let bank_type = e.target.value;

        let bank_accounts = [];

        let selectcustomerbank = {};

        let customerbank_id = e.target.value;

        for(let i = 0; i< this.state.permit.customer_banks.length; i++){

            // console.log(dagonbank_id + this.permit.specfic_bank_accounts[i].id)
            if(customerbank_id == this.state.permit.customer_banks[i].id) {


                selectcustomerbank.id = this.state.permit.customer_banks[i].id;
                selectcustomerbank.bank_acc_name  = this.state.permit.customer_banks[i].bank_acc_name;
                selectcustomerbank.bank_type  = this.state.permit.customer_banks[i].bank_type;
                selectcustomerbank.bank_num  = this.state.permit.customer_banks[i].bank_num;
            }

        }

        this.setState(prevState => ({
            form: {
                ...prevState.form,           // copy all other key-value pairs of food object
                customerbank: selectcustomerbank,
            }
        }))


        e.preventDefault();
    }

    onBankCopyChange() {

        //alert("ok");

        window.navigator.clipboard.writeText(this.state.form.dagonbank.cardnum )
        swal(this.state.form.dagonbank.cardnum + " Copied")

    }

    onListBankTypeChange(e, id) {

        // alert(e)

        let bank_type;


        if(e === undefined){
            bank_type = 1;
        }

        if(id === undefined){
            return
        } else {
            bank_type = id;
        }

        // alert(bank_type)
        // alert(id)

        // console.log(e)

        let find = 0;
        let dagonbank = this.state.form.dagonbank;
        let bank_accounts = [];
        let specfic_cus_bank_accounts = [];
        let specfic_bank_accounts = [];

        let selectcustomerbank = {};

        // let bank_type = this.form.customerbank.bank_type;

        // console.log(this.permit.customer_banks.length + "-----")


        {/*console.log(this.state.permit.customer_banks)*/}

        for(let j = 0; j<this.state.permit.bank_accounts.length; j++){

            if(bank_type == this.state.permit.bank_accounts[j].bank_type) {
                bank_accounts.push({ id : this.state.permit.bank_accounts[j].id, bank_type: this.state.permit.bank_accounts[j].bank_type, shortcode : this.state.permit.bank_accounts[j].shortcode, holdername :  this.state.permit.bank_accounts[j].holdername, cardnum :  this.state.permit.bank_accounts[j].cardnum })
            }

            // console.log(this.permit.bank_accounts[i].shortcode+"--------")
        }



       {/* if(specfic_cus_bank_accounts.length > 0) {
            selectcustomerbank.id = specfic_cus_bank_accounts[0].id;
            selectcustomerbank.bank_acc_name  = specfic_cus_bank_accounts[0].bank_acc_name;
            selectcustomerbank.bank_type  = specfic_cus_bank_accounts[0].bank_type;
            selectcustomerbank.bank_num  = specfic_cus_bank_accounts[0].bank_num;
        }*/}

        if(bank_accounts.length > 0){

                let rannum = Math.floor((Math.random() * bank_accounts.length));

                dagonbank.id = bank_accounts[rannum].id;
                dagonbank.holdername = bank_accounts[rannum].holdername;
                dagonbank.bank_type = bank_accounts[rannum].bank_type;
                dagonbank.cardnum = bank_accounts[rannum].cardnum;
        }



        // this.setState({ permit : { bank_accounts : [] }});
        // this.setState({ permit : { bank_accounts : bank_accounts }});
        specfic_bank_accounts = [];
        specfic_bank_accounts = bank_accounts;

        {/*specfic_cus_bank_accounts = [];
        specfic_cus_bank_accounts = specfic_cus_bank_accounts; */}

        if(find == 0) {
            selectcustomerbank.id = 1;
            selectcustomerbank.bank_acc_name = "Unknown";
            selectcustomerbank.bank_type  = bank_type;
            selectcustomerbank.bank_num = "";
        }

       {/* console.log(selectcustomerbank)*/}

        this.setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,           // copy all other key-value pairs of food object
                customerbank: selectcustomerbank,
                dagonbank : dagonbank
            },
            permit : {
                ...prevState.permit,
                specfic_cus_bank_accounts : specfic_cus_bank_accounts,
                specfic_bank_accounts : specfic_bank_accounts
            }
        }))

        if(e === undefined){
            return ;
        } else {
            e.preventDefault();
        }



        {/*this.setState({ step: "list" }); */}
        {/*
        console.log(this.state.permit.specfic_cus_bank_accounts)*/}
      {/*  console.log(this.state.form.customerbank)

        return */}
    }

    setAmount(text){

        let bank_type = this.state.form.bank_type;

        this.setState(prevState => ({
            ...prevState,
            form: {
                ...prevState.form,           // copy all other key-value pairs of food object
                amount : text
            }
        }))

    }

    showErrorAlert(message ,type ) {
        // let message = "message";
        // let alertId = StatusAlertService.showError(message);
        // // console.log(alertId)
        // this.setState({ alertId });

        let output = "";
        if(type == "multi") {

            for (var i = 0; i < message.length; i++) {
                output += message[i];
            }

        } else {
            output = message;
        }

        // console.log(output)

        let alertId = StatusAlertService.showError(output);
        // console.log(alertId)
        this.setState({ alertId });

    }

    removeAlert() {
        StatusAlertService.removeAlert(this.state.alertId);
    }

    showMessage() {


        // swal("Please check bank transaction id");

        swal("Request Top-up", {
            title: "Request Top-up",
            text: this.state.message,
          buttons: {
            // cancel: "Run away!",
            ok: {

              value: "catch",
            }
          },
        })
        .then((value) => {
          switch (value) {

            // case "defeat":
            //   swal("Pikachu fainted! You gained 500 XP!");
            //   break;

            case "catch":
                this.props.history.push("/more/wallet-store");
              // swal("Gotcha!", "Pikachu was caught!", "success");
              break;

            default:
              swal("Got away safely!");
          }
        });

    }

    showSuccessAlert(message) {
        const alertId = StatusAlertService.showSuccess(message);
        this.setState({ alertId });
    }

    removeAlert() {
        StatusAlertService.removeAlert(this.state.alertId);
    }



    inputNumberChangeHandler(e) {
        let form = {...this.state.form};
        if(Number(e.target.value)) {
            form[e.target.name] = Number(e.target.value);
            this.setState({
                form
            });
        }

    }

    inputChangeHandler(e) {
        let form = {...this.state.form};
        form[e.target.name] = e.target.value;
        this.setState({
            form
        });
    }

    sendTransactionApi() {
        var self = this;
        let formdata = this.state.form;
        // let dataurl = window.BPdata.apiUrl+'/user/login';
        const dataurl = window.BPdata.apiUrlCMS+"/transaction/create";

        if(this.state.form.customerbank.id) {
            console.log("ok")
           {/* alert(this.state.form.dagonbank.bank_type)*/}
           {/* alert(this.state.form.customerbank.id)*/}
        } else {
            swal("Please check your Bank Account")
            return;
        }

        if(this.state.form.bank_transaction_id == "") {
            swal("Please check bank transaction id");
            return;
        }

        if(this.state.form.amount <= 0) {
            swal("Please check your Amount");
            return;
        }

        if(this.state.bullet == 1) {

            swal("သင့်ရဲ့ ငွေသွင်းမှု့အားလုပ်ဆောင်နေပါသည် ခေတ္တ စောင့်ဆိုင်းပေးပါရှင့်");
            return;
        }

        if(this.state.bullet == 0) {

            this.setState({
                    bullet : 1,
            });
        }


        axios({
            method: 'post',
            url: dataurl,
            data: { body : formdata },
            headers: {
                'content-type': 'application/json;charset=UTF-8',
                "X-Trident-Token" : window.BPdata.token,
                "BP-Agent"         : window.BPdata.rand_compound
            },
        })
        .then(function (response) {

            // console.log(response.data.data.message)

            if(response.data.status_code == 200) {

                // alert("200");

                self.setState({
                    message : response.data.message ,
                    // step : "profile",
                });
                // localStorage.setItem(window.BPdata.time, response.data.data.token );
                // localStorage.setItem('username', response.data.data.username );
                // localStorage.setItem('reset', 1);
                // self.props.history.push("/");
                self.showMessage();

            }

            if(response.data.status_code == 404) {
                if(response.data.message.length > 0){
                     swal(response.data.message);

                    self.setState({
                            bullet : 0,
                    });

                } else {
                    swal( window.lang.errors.contact_cs );
                }

            }



            //console.log(localStorage.getItem(window.BPdata.time))
            //console.log(response.data.success);
        })
        .catch(function (error) {
            // console.log(error);
        });
    }



    fetchData() {
        //localStorage.getItem(window.BPdata.time)
        let self = this;
        const url = window.BPdata.apiUrlCMS+'/player/show?provider_id='+window.BPdata.provider_id;
        //const url = window.BPdata.apiUrl+'/user/token/'+localStorage.getItem(window.BPdata.time);

        axios({
          method: 'post',
          url: url,
          data: { token : localStorage.getItem(window.BPdata.time) },
          headers: { 'Content-Type': 'application/json;charset=UTF-8',
                "X-Trident-Token" : window.BPdata.token,
                "BP-Agent"         : window.BPdata.rand_compound,
                "BP-Time"         : localStorage.getItem(window.BPdata.time)
               },
        })
        .then(resp => {

            if(resp.data.data) {

                this.setState(prevState => ({
                  wallet : resp.data.customer_wallet,
                  permit : {
                    ...prevState.permit,
                    customer_banks : resp.data.data.customer_banks,
                    specfic_bank_accounts : resp.data.bank_accounts,
                    bank_accounts : resp.data.bank_accounts,
                  }
                }))

                self.onListBankTypeChange(1,1);

                localStorage.setItem("wallet", JSON.stringify(resp.data.customer_wallet) )

            } else {

                swal( window.lang.errors.contact_cs );

            }

          })
          .catch(function(error) {
            // console.log(error);
          });


    }



    changeBankType(step) {

        this.setState({
                form :  {
                    bank_type : step
                }
            });

        return;

    }

    changeSubMenu(step) {

        if(this.state.form.amount <= 0 || this.state.form.amount == "" ) {
            swal("Please check your Amount");
            return;
        }

        this.setState({ step : step});

        // console.log(step + "-------")

        if(step == "list") {
            let backLink = "/more/wallet-store/top-up";

            this.setState({ backLink : backLink});

            // console.log(backLink + "-------")
        }





    }


    hisUrl(url) {
        if(localStorage.getItem("route")) {
          let menu = JSON.parse(localStorage.getItem("route"));
          //console.log(menu.length+"-------------")
          if(menu.length === "0") {
            let data = ["/"];
            localStorage.setItem("route", JSON.stringify(data) );
          }
          if(menu.length > 0) {
            //console.log(menu[menu.length - 1] + "-" + url )
              if(menu[menu.length - 2] === url ){
                  menu.pop();
                  //window.BPdata.route = JSON.stringify(menu);
                  localStorage.setItem("route", JSON.stringify(menu) );
                  //localStorage.setItem("route", JSON.stringify(menu) );
              }
              else if(menu[menu.length-1] !== url ){
                  menu.push(url)
                  //window.BPdata.route = JSON.stringify(menu);
                  localStorage.setItem("route", JSON.stringify(menu) );

              }
              //window.BPdata.preurl =  menu[menu.length-1];
              window.BPdata.preurl =  menu[menu.length-2];
              //console.log(window.BPdata.preurl)
          }
        }
      }



    componentDidMount() {

        // this.showMessage();

        if(localStorage.getItem(window.BPdata.time)) {
          if(localStorage.getItem(window.BPdata.time) !== "0" ) {
            //localStorage.setItem(window.BPdata.time, true);

            this.setState({ step: "grid" });
            {/*this.setState({ step: "list" }); */}
          } else {
             this.props.history.push("/more/sign-up");
            }
        } else {
            this.props.history.push("/more/sign-up");
        }

        if(localStorage.getItem('contest_advertise')) {

            if(JSON.parse(localStorage.getItem("contest_advertise")) != null) {
              let contest_advertise = JSON.parse(localStorage.getItem("contest_advertise"));
              this.setState({advertise: contest_advertise})
            }
        }

        this.hisUrl('/contest');

        this.fetchData();
        // this.apifetchurl();

        clearTimeout(window.BPdata.liveurl);

    }

    UNSAFE_componentWillUpdate() {
        //this.hisUrl('/more');
    }


    viewHandler() {


        switch(this.state.step) {
            case "grid": return this.gridBlock();
            case "list": return this.listBlock();
            // case "banklist": return this.banklistBlock();
            default:      return "";
        }
    }

    listBlock() {
        return  <div className="row text-left page-contest">

                    <div className="grid-90 text-center">
                        <br />
                        <br />
                    </div>

                    <div className="grid-90 text-left">
                        <div className="row">
                            <div className="grid-60 left">
                                <h4>Transfer Bank Info</h4>
                                <br /><br />
                            </div>

                            <div className="grid-40 right text-right">

                            </div>

                        </div>
                        <div className="form-group text-left">
                            <div className="grid-90">



                                <div className="row  bg-white pl-5 p-10 pb-30">
                                    {/*<label className="control-label">Bank</label>*/}

                                   {/* <select className="form-control" value={this.state.form.dagonbank.id} onChange={(e) => this.onCompanyBankTypeChange.call(this, e)}>
                                        {this.state.permit.specfic_bank_accounts.map(({ id, shortcode }, index) => <option value={id} >{shortcode}</option>)}
                                    </select> */}
                                    <div className="grid-90 text-center">
                                            <br />
                                         {this.state.payment.map((dataItem, i) => (

                                                (dataItem.id == this.state.form.customerbank.bank_type ) ? <img src={dataItem.image} alt={window.lang.title.forum} style={{marginBottom:"5px",marginRight:"15px"}} className="bg-gray text-center"  onClick={(e) => this.onListBankTypeChange.call(this, e, dataItem.id)} /> : ""

                                        ))}

                                    </div>
                                    <br />
                                    <div className="grid-90 text-left pl-10">
                                        <span> <b>{ window.lang.form.amount } :</b> {this.state.form.amount} </span>
                                        <br />
                                        <br />
                                        <b className="color-black">ငွေလွှဲရန် အကောင့်</b>
                                        <br />
                                        <br />
                                        <span className="color-black"> <b className="color-black">Acc Name :</b> <span className="color-black">{this.state.form.dagonbank.holdername}</span> </span>
                                        <br />
                                        <br />
                                        <span className="color-black"> <b className="color-black">Acc Number :</b><span className="color-black"> {this.state.form.dagonbank.cardnum} 

                                            { (this.apkCheck() == false) ?  <span className="text-right color-blue pointer" onClick={this.onBankCopyChange}>&nbsp;&nbsp;ကူးယူမည်</span>  : <span className="text-left  color-black pointer" ><br /><br />ဖုန်းနံပါတ်ဖိ၍ Copy ကိုရွေးပေးပါရှင့်</span> }
                                            
                                            {/*<img src="/img/copy.png" height="20px" />*/}
                                            </span> </span>

                                        <br />
                                    </div>

                                </div>



                              {/*  <div className="form-group">
                                    <label className="control-label">Time </label>
                                    <input type="text" className="form-control"  onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.transaction_date}/>
                                </div> */}


                                    <div><br/>
                                </div>
                                        </div>
                        </div>
                        <div className="form-group">
                            <label className="control-label">Bank Transaction ID  ( { window.lang.messages.last_six_digit_transaction }  ) </label> <input name="bank_transaction_id" type="number" value="0" required="required" className="form-control" autoComplete="off" onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.bank_transaction_id} />
                        </div>
                        <br />
                        <div className="form-group text-center">
                            <a href={"viber://contact?number=%2B"+this.state.viber_ph_1} >If any trobule, Please Contact to our viber </a>
                            </div>
                            <br />
                            <input className="btn btn-yellow   pointer grid-100" value={window.lang.form.submit} onClick={this.sendTransactionApi}/>
                        </div>
                </div>

    }

    gridBlock() {
        return <div className="row text-left page-contest">

                   {/* <div className="grid-100 text-center">

                    <br />
                    {this.state.permit.bank_types.map(({ id, shortcode }, index) => <span className="btn btn-yellow   pointer mr-4" value={id}  onClick={(e) => this.onListBankTypeChange.call(this, e, id)}>{shortcode}</span> )}

                    </div>*/}

                    <div className="grid-100 text-left">
                        <div className="form-group text-left">
                            <br />
                            <div className="row text-center">
                                { window.lang.messages.choose_top_bank }

                            </div>
                            <br />
                            <div className="row text-center paymentlist">
                                <div className="grid-90">

                                {this.state.payment.map((dataItem, i) => (

                                        (dataItem.id == this.state.form.customerbank.bank_type ) ? <img src={dataItem.image} alt={window.lang.title.forum} style={{height:"80px",marginBottom:"5px",marginRight:"15px", borderRadius: '15px'}} className="bg-gray"  onClick={(e) => this.onListBankTypeChange.call(this, e, dataItem.id)} /> : <img src={dataItem.non_image} alt={window.lang.title.forum} style={{height:"80px",marginBottom:"5px",marginRight:"15px", borderRadius: '15px'}} className="bg-gray"  onClick={(e) => this.onListBankTypeChange.call(this, e, dataItem.id)} />

                                ))}

                                </div>
                               {/* <Grid data={this.state.payment}
                                  columnNum={2}
                                  renderItem={dataItem => (
                                    <div className="row">
                                        {
                                            (dataItem.id == this.state.form.customerbank.bank_type ) ? <img src={dataItem.image} alt={window.lang.title.forum} style={{height:"80px",marginBottom:"5px"}} className="bg-gray"  onClick={(e) => this.onListBankTypeChange.call(this, e, dataItem.id)} /> : <img src={dataItem.non_image} alt={window.lang.title.forum} style={{height:"80px",marginBottom:"5px"}} className="bg-gray"  onClick={(e) => this.onListBankTypeChange.call(this, e, dataItem.id)} />
                                        }
                                    </div>
                                  )}
                                />
*/}                            </div>

                        </div>
                    </div>

                    <div className="grid-90 grid-s-90 left">
                        <div className="form-group text-left">
                            <hr />
                            { window.lang.messages.cash_amount }
                            <br />
                            <br />

                            <input placeholder="ငွေပမာဏ ထည့်သွင်းရန်" type="number" className="form-control text-center" name="amount"   onChange={(e) => this.inputChangeHandler.call(this, e)} value={this.state.form.amount} /><br />
                            <br />
                        </div>
                    </div>

                    <div className="grid-90 text-left">
                        <div className="form-group text-left">
                            <br />
                            <div className="row text-center moneyamountlist">
                                <Grid data={window.lang.moneyamountlist}
                                  columnNum={3}
                                  renderItem={dataItem => (
                                        <div style={{ color: '#888', fontSize: '14px', marginTop: '0px',textAlign: 'center', padding: '10px', width: '90%'}} className="cont-wrap-link" onClick={this.setAmount.bind(this, dataItem.num)} >

                                          <h3 className="sportsbook color-white">{dataItem.text}</h3>

                                        </div>
                                  )}
                                />
                            </div>
                            <br />
                            <input className="btn btn-yellow   pointer grid-100" value={window.lang.form.submit} onClick={this.changeSubMenu.bind(this,"list")} />

                        </div>
                    </div>
                </div>
    }

    render() {
        return (

            <div className="page">
                <AppHeader backLink={this.state.backLink} titleType="3" title={window.lang.title.top_up} isLoading="false" />
                <div className="page-content text-center">

                    <div className="main-content text-center">
                        <div className="main-content text-center">
                            {(this.state.message !== "") ? <div className="grid"><StatusAlert/></div>: ""}

                            { this.viewHandler() }


                        </div>
                    </div>

                    <br />

                  {/*  <div className="ant-back-footer">
                        <div className="ant-back-top">
                            <div className="ant-back-top-content">
                                <Link to={window.BPdata.preurl} className="color-green" >
                            { (JSON.parse(localStorage.getItem("route")) !== null)
                            ? ((this.state.url !== "/" || JSON.parse(localStorage.getItem("route"))[JSON.parse(localStorage.getItem("route")).length-1] !== "/") ?  <img src="/img/back-arrow.png" alt="back" />: ""  ) : "" }
                            </Link>
                            </div>
                        </div>
                    </div>*/}
                </div>
                <footer  className="App-footer">

                  <div className="App-footer-inner">
                    {/* {window.BPdata.footerMenu} */}
                    <Grid data={window.lang.footer}
                        columnNum={5}
                        renderItem={dataItem => (

                          <Link to={dataItem.url} className="cont-wrap-link hvr-pulse-shrink" style={{width: '100%'}}>
                                { (window.BPdata.footerMenu === dataItem.check )  ? (dataItem.text == "My Profile") ?  <span className="color-white"><img src={dataItem.icon}  height="25px"/><br />{(this.checklogin()) ? dataItem.text : "Login / Register" }</span> : <span className="text-white"><img src={dataItem.icon_off}  height="25px"/><br /><span >{dataItem.text}</span> </span> : <span className="text-white"><img src={dataItem.icon}  height="25px"/><br />{dataItem.text} </span> }

                          </Link>
                        )}
                      />

                  </div>
                </footer>
            </div>
        );
    }
}

export default WalletTopUpStore;
